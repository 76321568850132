import { usdFormat } from "../../../../utils";
import { DiscountsReportRow } from "./type";
import { ColDef } from "ag-grid-community";

// Column Definitions
const generateColDefs = (): ColDef<DiscountsReportRow>[] => [
  {
    headerName: 'Discount Name',
    field: 'discount_name',
    filter: 'agTextColumnFilter',
    cellStyle: params => {
      if (params.data?.isTotal) {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Applied Discount Amount',
    valueGetter: p => p.data?.applied_discount_amount,
    valueFormatter: params => usdFormat.format((params.value ?? 0) / 100),
    filter: 'agTextColumnFilter',
    cellStyle: params => {
      if (params.data?.isTotal) {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Count',
    valueGetter: p => p.data?.count,
    filter: 'agNumberColumnFilter',
    cellStyle: params => {
      if (params.data?.isTotal) {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
];

// Data Formatting Function
export const formatData = (data: DiscountsReportRow[]) => {
  if (!data) return [];

  // Combine all discount objects from discount_json fields into a single array
  const allDiscounts = data.flatMap(row => row.discount_json || []);

  const groupedRows: Record<string, DiscountsReportRow> = {};
  let totalDiscounts = 0;
  let totalCount = 0;

  allDiscounts.forEach((discount) => {
    const discountName = discount.name;
    const amount = discount.applied_discount || 0;

    if (groupedRows[discountName]) {
      groupedRows[discountName].applied_discount_amount += amount;
      groupedRows[discountName].count++;
    } else {
      groupedRows[discountName] = {
        discount_name: discountName,
        applied_discount_amount: amount,
        count: 1,
        isTotal: false,
        discount_json: [],
      };
    }

    totalDiscounts += amount;
    totalCount += 1;
  });

  groupedRows['Total'] = {
    discount_name: 'Total',
    applied_discount_amount: totalDiscounts,
    count: totalCount,
    isTotal: true,
    discount_json: [],
  };

  return Object.values(groupedRows);
};

export const colDefs: ColDef<DiscountsReportRow>[] = generateColDefs();
