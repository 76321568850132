import { useLocation } from "react-router-dom";
import {WEB_CHECKOUT_REPORT, WEB_CHECKOUT_REPORT_COUNT} from "./fragment";
import { ReportFilters } from "../../components/ReportFilters";
import ReportHeader from "../../components/ReportHeader";
import { userStore } from "../../../../store/user";
import { createApolloClient } from "../../../../providers/ApolloClientFactory";
import { organizationStore } from "../../../../store/organization";
import { WebCheckoutReportRow } from "./type";
import React, { useEffect, useRef, useState } from "react";
import { eventStore } from "../../../../store/event";
import { GridApi, SizeColumnsToContentStrategy } from "ag-grid-community";
import { SecondaryButton } from "../../../../components/Button";
import { AgGridReact } from "ag-grid-react";
import { colDefs } from "./helpers";
import Loader from "../../../../components/Loader";

const client = createApolloClient();
const { getState } = userStore;
const { getState: getOrganizationState } = organizationStore;
const { getState: getEventState } = eventStore;
const PAGE_SIZE = 50000;
const DEBOUNCE_DELAY = 100;

export const WebCheckoutReport = () => {
  const [data, setData] = useState<WebCheckoutReportRow[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const gridRef = useRef<GridApi>();
  const rowHeight = 50;
  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: "fitCellContents",
  };
  const location = useLocation();
  const fetchTimeoutRef = useRef<any>(null);
  const handleExportCSV = () => {
    if (gridRef.current) {
      const timestamp = new Date().toISOString().replace(/[-:]/g, "");
      const params = {
        fileName: `WebCheckoutReport_${timestamp}.csv`,
        columnSeparator: ",",
        skipHeader: false,
        allColumns: true,
      };
      gridRef.current.exportDataAsCsv(params);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const debouncedFetch = () => {
      clearTimeout(fetchTimeoutRef.current);
      fetchTimeoutRef.current = setTimeout(fetchWebCheckout, DEBOUNCE_DELAY);
    };
    const fetchWebCheckout = async () => {
      setIsLoading(true);
      try {
        const organizationId = getOrganizationState().organizationId;
        const { eventId } = getEventState();
        const user = getState().user;
        console.log(organizationId)
        const search = new URLSearchParams(location.search);
        const where = search.get("where");
        let whereVariable: any = where ? JSON.parse(where) : {};
        if (organizationId) {
          whereVariable = {
            ...whereVariable,
            organization_id: {
              _eq: organizationId,
            },
          };
        }

        if (eventId) {
          whereVariable = {
            ...whereVariable,
            event_id: {
              _eq: eventId,
            },
          };
        } else if (user?.events) {
          whereVariable = {
            ...whereVariable,
            event_id: {
              _in: user?.events,
            },
          };
        }
        const { data: totalData } = await client.query({
          query: WEB_CHECKOUT_REPORT_COUNT,
          variables: {
            where: whereVariable,
            limit: 0,
          },
        });
        const totalCount = totalData.total.aggregate.count;
        console.log(totalCount);
        let offset = 0;
        let chunkData: WebCheckoutReportRow[] = [];

        while (offset < totalCount) {
          const { data: chunk } = await client.query({
            query: WEB_CHECKOUT_REPORT,
            variables: {
              where: whereVariable,
              limit: PAGE_SIZE,
              offset: offset,
            },
          });
          chunkData = [...chunkData, ...(chunk.web_checkout_orders || [])];
          offset += PAGE_SIZE;
        }

        if (isMounted) {
          setData(chunkData);
        }
      } catch (error) {
        console.error("Error fetching Web Checkout Records:", error);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };
    debouncedFetch();
    return () => {
      isMounted = false;
      clearTimeout(fetchTimeoutRef.current);
    };
  }, [location.search]);
  const gridOptions = {
    enableCellTextSelection: true,
  };

  console.log({data})
  return (
    <>
      <div className="bg-white px-4 pb-1 mb-3 rounded-md">
        <ReportHeader title="Web Checkout Report" />
        <ReportFilters
          includeDates
          includeTime
          customQueryPaths={{
            dateFrom: "_and[0].created_at",
            dateTo: "_and[1].created_at",
          }}
        />
        <div className="flex">
          <div className="ml-auto">
            <SecondaryButton onClick={handleExportCSV}>
              Export to CSV
            </SecondaryButton>
          </div>
        </div>
      </div>
      <div style={{ height: "60vh" }}>
        <div
          className={"ag-theme-quartz"}
          style={{ width: "100%", height: "100%" }}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <AgGridReact
              rowData={data}
              columnDefs={colDefs}
              rowHeight={rowHeight}
              autoSizeStrategy={autoSizeStrategy}
              pagination={true}
              gridOptions={gridOptions}
              paginationPageSize={100}
              // pinnedBottomRowData={totalRow}
              onGridReady={(params) => (gridRef.current = params.api)} // Set GridApi reference
            />
          )}
        </div>
      </div>
    </>
  );
};
