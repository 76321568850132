import moment from "moment";
import { LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import { ShowRow } from "../../components/ShowRow";
import { ShowLayout } from "../../layout/ShowLayout";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { Order } from "../../types/order";
import { capitalize } from "../../utils";
import { RefundModal } from "./components/RefundModal";
// import { OrdersPartialRefundButton } from "./actions/refundPartialAction";
import {GET_ORDER, GET_ORDER_TICKETING} from "./queries";
import { TotalList } from "./components/Total";
import { ItemsTable } from "./components/ItemsTable";
import { DiscountTable } from "./components/DiscountTable";
import { TransactionTable } from "./components/TransactionTable";
import ButtonGroup from "../../components/Button";
import { generateReceipt } from "./actions/generate-new-receipt";
import { useRef, useState } from "react";

const client = createApolloClient();

export const orderTicketingShowLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id, reference_id } = params;

  const { data } = await client.query({
    query: GET_ORDER_TICKETING,
    variables: { id, reference_id },
  });
  return data.orders_ticketing_by_pk;
};

export const OrderTicketingShow = () => {
  const [generating, setGenerating] = useState(false);
  const [partial, setPartial] = useState(false);
  const refundButton = useRef<HTMLButtonElement>(null);
  const order = useLoaderData() as Order;

  return (
    <>
      <ShowLayout
        header={`Order #${order.id}`}
        actions={
          <div className="flex space-x-2">
            <ButtonGroup
              loading={generating}
              mainButton={{
                name: "Generate Receipt",
                onClick: async () => {
                  setGenerating(true);
                  await generateReceipt(order.reference_id);
                  setGenerating(false);
                },
              }}
              buttons={[
                {
                  name: "Void",
                  onClick: () => {
                    refundButton.current?.click();
                  },
                },
                {
                  name: "Refund",
                  onClick: () => {
                    setPartial(true);
                    refundButton.current?.click();
                  },
                },
              ]}
            />
          </div>
        }
      >
      <ShowRow
          label="Tablet Transaction Time"
          value={moment(order.transaction_at).format("lll")}
      />
        <ShowRow
          label="Server Transaction Time"
          value={moment(order.created_at).format("lll")}
        />
      <ShowRow
          label="Last Updated Time"
          value={moment(order.updated_at).format("lll")}
      />
        <ShowRow label="Phone Number" value={order.phone_number} />
        <ShowRow
          label="Order Status"
          value={
            order.status === "refund" ? "Refunded" : capitalize(order.status)
          }
        />
        <ShowRow label="Reference ID" value={order.reference_id} />
        <ShowRow label="MX Ref ID" value={order.mx_ref_id} />
          <ShowRow label="Event" value={order.event?.name} />
          <ShowRow label="Location" value={order.location?.name} />
          <ShowRow label="Vendor" value={order.vendor?.name} />
          <ShowRow label="Menu" value={order.menu?.name} />
        <ShowRow label="UID" value={order.uid} />

        <ShowRow label="Clerk" value={order.user?.username} />
        <ShowRow label="Build ID" value={order.new_order_payload ? order.build_id : order.device_id} />
        <ShowRow label="Device Android ID" value={order.new_order_payload ? order.android_device_id : order.device_app_id} />

      </ShowLayout>
       <div className="mt-2">
        <ShowLayout header="Items">
          <ItemsTable items={order.items} />
        </ShowLayout>
      </div>
      {order.discount && (
        <div className="mt-2">
          <ShowLayout header="Discounts">
            <DiscountTable />
          </ShowLayout>
        </div>
      )}
      <div className="mt-2">
        <ShowLayout header="Transactions">
          <TransactionTable />
        </ShowLayout>
      </div>
      <div className="mt-2">
        <TotalList />
      </div>
     <RefundModal partial={partial} onClose={() => setPartial(false)}>
        {({ setOpen }) => (
          <button ref={refundButton} onClick={() => setOpen(true)} />
        )}
      </RefundModal>
    </>
  );
};
