import {
  ActionFunctionArgs,
  Form,
  redirect,
} from "react-router-dom";
import toast from "react-hot-toast";
import { createApolloClient } from "../../../providers/ApolloClientFactory";
import { Input, TextArea } from "../../../components/inputs/Input";
import { FormLayout } from "../../../layout/FormLayout";

import { CREATE_BLOG_POST } from "./queries_mutations";


const client = createApolloClient();


export const blogpostCreateAction = async ({
  request,
}: ActionFunctionArgs) => {
  const data = await request.formData();

  try {
    await client.mutate({
      mutation: CREATE_BLOG_POST,
      variables: {
        reference_label: data.get("title"),
        reference_value: data.get("content"),
      },
    });
    toast.success("Blog Post Created Successfully");
    return redirect("/dashboard");
  } catch (error) {
    toast.error("Failed To Create Blog Post: " + error);
  }
};


export const BlogPostCreate = () => {

  return (
    <Form action={`/blogpost/create`} method="put">
      <FormLayout>
        <div className="col-span-4 sm:col-span-3">
          <Input
            label="Title"
            type="text"
            name="title"
          />
        </div>
        <div className="col-span-6">
          <TextArea
            label="Content"
            type="text"
            name="content"
          />
        </div>
      </FormLayout>
    </Form>
  );
};
