import { gql } from "@apollo/client";

export const REPORT_REFRESHES = gql`
query ReportRefreshes {
  latest_cron_job_log(order_by: {job_name: asc}) {
    end_time
    error_message
    job_id
    job_name
    start_time
    status
  }
}

`;


