import { usdFormat } from "../../../../utils";
import { ProductMixReportRow } from "./type";
import {ColDef} from "ag-grid-community";


const generateColDefs = (): ColDef<ProductMixReportRow>[] => [
  {
    headerName: 'GL Account Name',
    pinned: 'left',
    valueGetter: params => {
      return params.data?.gl_account_name;
    },
    cellStyle: params => {
      if (params.data?.pos_name === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'GL Account Number',
    valueGetter: params => {
      return params.data?.gl_account_number;
    },
    cellStyle: params => {
      if (params.data?.pos_name === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Product Name',
    valueGetter: p => p.data?.pos_name,
    filter: 'agTextColumnFilter',
    cellStyle: params => {
      if (params.value === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Product Type',
    valueGetter: params => {
      return params.data?.product_type;
    },
    cellStyle: params => {
      if (params.data?.pos_name === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Product Description',
    valueGetter: params => {
      return params.data?.description;
    },
    cellStyle: params => {
      if (params.data?.pos_name === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Current Price',
    valueGetter: params => {
      return params.data?.item_price;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.pos_name === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Total Gross Sales',
    valueGetter: p => p.data?.total_gross_sales,
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.pos_name === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Total Discount',
    valueGetter: params => {
      return params.data?.total_discounts;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.pos_name === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Total Tax',
    valueGetter: p => p.data?.total_tax,
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.pos_name === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Total Net Sales',
    valueGetter: params => {
      return params.data?.total_net_sales;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.pos_name === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Gross Credit Sales',
    valueGetter: params => {
      return params.data?.gross_credit_sales;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.pos_name === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Gross Cash Sales',
    valueGetter: params => {
      return params.data?.gross_cash_sales;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.pos_name === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
];

export const colDefs: ColDef<ProductMixReportRow>[] = generateColDefs();

export const formatData = (data: ProductMixReportRow[]) => {
  if (!data) return [];

  let totalRow: ProductMixReportRow = {
    id: "total-row",
    pos_name: "Total",
    total_gross_sales: 0,
    total_tax: 0,
    total_discounts: 0,
    total_refunds: 0,
    total_net_sales: 0,
    cash_units_sold: 0,
    credit_units_sold: 0,
    rfid_units_sold: 0,
    cash_balance_units_sold: 0,
    token_units_sold: 0,
    total_units_sold: 0,
    location_id: 0,
    order_id: "",
    transaction_date: "",
    item_price: 0,
    token_price: 0,
    redeemable_token_id: 0,
    product_type: "",
    gross_cash_sales: 0,
    gross_cash_balance_sales: 0,
    gross_credit_sales: 0,
    gross_rfid_sales: 0,
    gross_promo_balance_sales: 0,
    gross_token_sales: 0,
    description: "",
    gl_account_number: "",
    gl_account_name: "",
  };
  const groupedRows: Record<string, ProductMixReportRow> = {};

  data.forEach((curr) => {
    const posName = curr.pos_name;
    const item_price = curr.item_price / 100;
    const token_price = curr.token_price;
    const product_type = curr.product_type;
    const description = curr.description;
    const redeemable_token_id = curr.redeemable_token_id;
    const total_gross_sales = (curr.gross_cash_balance_sales + curr.gross_cash_sales + curr.gross_credit_sales + curr.gross_promo_balance_sales + curr.gross_rfid_sales + curr.gross_token_sales) / 100;
    const total_discounts = curr.total_discounts / 100;
    const gross_cash_sales = curr.gross_cash_sales / 100;
    const gross_credit_sales = curr.gross_credit_sales / 100;
    const total_refunds = curr.total_refunds / 100;
    const total_net_sales = ((curr.gross_cash_balance_sales + curr.gross_cash_sales + curr.gross_credit_sales + curr.gross_promo_balance_sales + curr.gross_rfid_sales + curr.gross_token_sales) / 100) -
        ((curr.gross_token_sales + curr.total_refunds + curr.gross_promo_balance_sales + curr.total_discounts + curr.total_tax) / 100);
    const cash_units_sold = curr.cash_units_sold;
    const cash_balance_units_sold = curr.cash_balance_units_sold;
    const credit_units_sold = curr.credit_units_sold;
    const rfid_units_sold = curr.rfid_units_sold;
    const total_units_sold = curr.total_units_sold;
    const total_tax = curr.total_tax / 100;
    const token_units_sold = curr.token_units_sold;

    if (groupedRows[posName]) {
      const row: ProductMixReportRow = groupedRows[posName];
      row.total_gross_sales += total_gross_sales;
      row.total_tax += total_tax;
      row.total_discounts += total_discounts;
      row.total_refunds += total_refunds;
      row.total_net_sales += total_net_sales;
      row.cash_units_sold += cash_units_sold;
      row.cash_balance_units_sold += cash_balance_units_sold;
      row.credit_units_sold += credit_units_sold;
      row.rfid_units_sold += rfid_units_sold;
      row.token_units_sold += token_units_sold;
      row.total_units_sold += total_units_sold;
      row.gross_cash_sales += gross_cash_sales;
      row.gross_credit_sales += gross_credit_sales;
    } else {
      groupedRows[posName] = {
        ...curr,
        id: posName,
        total_units_sold,
        total_gross_sales,
        total_tax,
        item_price,
        product_type,
        description,
        total_discounts,
        total_net_sales,
        token_units_sold,
        token_price,
        redeemable_token_id,
        cash_units_sold,
        cash_balance_units_sold,
        credit_units_sold,
        rfid_units_sold,
        gross_credit_sales,
        gross_cash_sales,
      };
    }
    totalRow.total_gross_sales += total_gross_sales;
    totalRow.total_tax += total_tax;
    totalRow.total_discounts += total_discounts;
    totalRow.total_refunds += total_refunds;
    totalRow.total_net_sales += total_net_sales;
    totalRow.cash_units_sold += cash_units_sold;
    totalRow.cash_balance_units_sold += cash_balance_units_sold;
    totalRow.credit_units_sold += credit_units_sold;
    totalRow.rfid_units_sold += rfid_units_sold;
    totalRow.token_units_sold += token_units_sold;
    totalRow.total_units_sold += total_units_sold;
    totalRow.gross_credit_sales += gross_credit_sales;
    totalRow.gross_cash_sales += gross_cash_sales;
  });
  groupedRows[totalRow.id] = totalRow;

  return Object.values(groupedRows);
};

