import { Badge } from "../../components/Badge";
import {
  Link,
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
  useRevalidator,
} from "react-router-dom";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { organizationStore } from "../../store/organization";
import { GET_LIST_EVENTS } from "./queries";
import { Event } from "../../types/event";
import { useEffect, useRef, useState } from "react";
import moment from "moment/moment";
import { usePermissions } from "../auth/api";
import { eventStore } from "../../store/event";
import {
  ColDef,
  GridApi,
  SizeColumnsToContentStrategy,
} from "ag-grid-community";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Input } from "../../components/inputs/Input";
import { PrimaryButton, SecondaryButton } from "../../components/Button";
import { AgGridReact } from "ag-grid-react";
import { userStore } from "../../store/user";
import Switch from "react-switch";

const client = createApolloClient();
const { getState } = organizationStore;
const { getState: getEventStore } = eventStore;
const { getState: getUserStore } = userStore;

const buildSearch = (search: string) => [
  {
    name: {
      _ilike: `%${search}%`,
    },
  },
];

type CellRenderer = (params: {
  value:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  data: Event;
}) => JSX.Element | null;
const createCellRenderer = (
  permission: boolean,
  linkFunc: (params: { data: Event }) => string
): CellRenderer => {
  return (params) => {
    if (params.value === undefined) {
      return null;
    }
    return (
      <>
        {permission ? (
          <Link to={linkFunc(params)}>
            <Badge>{params.value}</Badge>
          </Link>
        ) : (
          <Badge>{params.value}</Badge>
        )}
      </>
    );
  };
};

export const eventsListLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const search = url.searchParams.get("search");
  const rawOrganizationId = getState().organizationId;
  const organizationId = parseInt(rawOrganizationId !== null ? rawOrganizationId.toString() : '', 10);
  const eventId = getEventStore().eventId;
  const user = getUserStore().user;
  let where: any = { _or: search ? buildSearch(search) : undefined };
  if (organizationId && organizationId !== 0) {
    where = {
      ...where,
      organization_id: {
        _eq: organizationId,
      },
    };
  }
  if ((user?.events?.length || 0) > 0) {
    where = {
      ...where,
      id: {
        _in: user?.events,
      },
    };
  }
  const { data } = await client.query({
    query: GET_LIST_EVENTS,
    variables: {
      where,
    },
  });
  return data.events;
};

export const EventsList = () => {
  const { editCreatePermission, ListPermission, moduleWisePermission } =
    usePermissions("events");
  const { editCreatePermission: paymentProcessorEditPermission } =
    usePermissions("payment_processors");
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const gridRef = useRef<GridApi>();
  const events = useLoaderData() as Event[];
  const [rowData, setRowData] = useState<Event[]>([]);
  const [isActiveToggle, setIsActiveToggle] = useState(true);
  useEffect(() => {
    const filteredItems = isActiveToggle
        ? events.filter((event) => event.is_active)
        : events;
    setRowData(filteredItems);
  }, [events, isActiveToggle]);
  const generateColDefs = (): ColDef<Event>[] => [
    {
      headerName: "Id",
      valueGetter: (p) => p.data?.id,
      cellRenderer: createCellRenderer(
        editCreatePermission,
        (p) => `/events/${p.data.id}/edit`
      ),
      filter: "agTextColumnFilter",
      pinned: "left",
    },
    {
      headerName: "Event Name",
      valueGetter: (p) => p.data?.name,
      cellRenderer: createCellRenderer(
        editCreatePermission,
        (p) => `/events/${p.data.id}/edit`
      ),
      filter: "agTextColumnFilter",
      pinned: "left",
    },
    {
      headerName: "Is Active?",
      valueGetter: (p) => p.data?.is_active,
      valueFormatter: (p) => {
        return p.data?.is_active ? "Yes" : "No";
      },
    },
    {
      headerName: "Payment Processor",
      valueGetter: (p) => p.data?.payment_processor_config?.payment_processor,
      cellRenderer: createCellRenderer(
        paymentProcessorEditPermission,
        (p) => `/payment-processors/${p.data?.payment_processor_id}/edit`
      ),
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Dynamic Descriptor",
      valueGetter: (p) => p.data?.dynamic_descriptor,
    },
    {
      headerName: "Timezone",
      valueGetter: (p) => p.data?.timezone,
    },
    {
      headerName: "Start Date",
      valueGetter: (p) => p.data?.start_date,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY") : "";
      },
    },
    {
      headerName: "End Date",
      valueGetter: (p) => p.data?.end_date,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY") : "";
      },
    },
    {
      headerName: "Updated By",
      valueGetter: (p) => p.data?.user?.username,
    },
    {
      headerName: "Created Date",
      valueGetter: (p) => p.data?.created_at,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss a") : "";
      },
    },
    {
      headerName: "Updated Date",
      valueGetter: (p) => p.data?.updated_at,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss a") : "";
      },
    },
  ];
  const colDefs: ColDef<Event>[] = generateColDefs();
  const rowHeight = 50;
  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: "fitCellContents",
  };
  const handleExportCSV = () => {
    if (gridRef.current) {
      const timestamp = new Date().toISOString().replace(/[-:]/g, "");
      const params = {
        fileName: `Events_${timestamp}.csv`,
        columnSeparator: ",",
      };
      gridRef.current.exportDataAsCsv(params);
    }
  };
  const gridOptions = {
    enableCellTextSelection: true,
  };
  return (
    <div style={{ height: "75vh" }}>
      <div
        className={"ag-theme-quartz"}
        style={{ width: "100%", height: "100%" }}
      >
        <div className=" grid gap-2 grid-cols-6">
          <Breadcrumbs/>
          <div className="col-span-1 col-start-4 mt-3 flex flex-col items-center">
            <span className="block text-sm font-medium text-gray-500">Active Only</span>
            <Switch
                checked={isActiveToggle}
                onChange={() => setIsActiveToggle((prev) => !prev)}
                onColor="#EE2E30"
                onHandleColor="#FFFFFF"
                handleDiameter={18}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={15}
                width={48}
            />
          </div>
          <div className="col-span-2 col-start-5 mt-2">
            <Input
                name="search"
                placeholder={`Search Events`}
                onChange={(s) => {
                  navigate(
                      s && typeof s === "string" && s.length > 0
                          ? `/events/list?search=${s}`
                          : `/events/list`
                  );
                  revalidator.revalidate();
                }}
            />
          </div>
          <div className="py-2 col-span-2 col-start-7">
            <SecondaryButton onClick={handleExportCSV}>
              Export to CSV
            </SecondaryButton>
            {editCreatePermission && (
                <PrimaryButton onClick={() => navigate(`/events/create`)}>
                  Create
                </PrimaryButton>
            )}
          </div>
        </div>
        <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            rowHeight={rowHeight}
            autoSizeStrategy={autoSizeStrategy}
            pagination={true}
            gridOptions={gridOptions}
            paginationPageSize={100}
            onGridReady={(params) => (gridRef.current = params.api)}
        />
      </div>
    </div>
  );
};
