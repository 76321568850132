import { gql } from "@apollo/client";

export const CREATE_INVOICE = gql`
  mutation CreateInvoice(
    $organizationId: Int!
    $eventId: Int!
    $referenceId: String!
    $fromName: String!
    $fromEmail: String!
    $billToName: String!
    $billToEmail: String!
    $billToPhoneNumber: String!
    $invoiceNumber: String!
    $invoiceDate: date
    $invoiceDueDate: date
    $invoiceType: String!
    $lineItems: String!
    $invoiceAmount: numeric
    $invoiceTaxPercentage: numeric!
    $invoiceServiceFeePercentage: numeric!
    $description: String!
    $totalAmount: numeric!
    $totalTax: numeric!
    $totalServiceFee: numeric!
    $grandTotalAmount: numeric!
    $last_updated_by: Int
  ) {
    insert_invoices_one(
      object: {
        status: "unpaid"
        organization_id: $organizationId
        event_id: $eventId
        reference_id: $referenceId
        from_name: $fromName
        from_email: $fromEmail
        bill_to_name: $billToName
        bill_to_email: $billToEmail
        bill_to_phone_number: $billToPhoneNumber
        invoice_number: $invoiceNumber
        invoice_date: $invoiceDate
        invoice_due_date: $invoiceDueDate
        invoice_type: $invoiceType
        invoice_line_items: $lineItems
        invoice_amount: $invoiceAmount
        tax_percentage: $invoiceTaxPercentage
        service_fee_percentage: $invoiceServiceFeePercentage
        description: $description
        total_amount: $totalAmount
        total_tax: $totalTax
        total_service_fee: $totalServiceFee
        grand_total_amount: $grandTotalAmount
        last_updated_by: $last_updated_by
      }
    ) {
      id
    }
  }
`;

export const UPDATE_INVOICE = gql`
  mutation UpdateInvoiceById(
    $organizationId: Int!
    $eventId: Int!
    $fromName: String!
    $fromEmail: String!
    $billToName: String!
    $billToEmail: String!
    $billToPhoneNumber: String!
    $invoiceNumber: String!
    $invoiceDate: date
    $invoiceDueDate: date
    $invoiceType: String!
    $lineItems: String!
    $invoiceAmount: numeric
    $invoiceTaxPercentage: numeric!
    $invoiceServiceFeePercentage: numeric!
    $description: String!
    $totalAmount: numeric!
    $totalTax: numeric!
    $totalServiceFee: numeric!
    $grandTotalAmount: numeric!
    $last_updated_by: Int
    $id: Int!
  ) {
    update_invoices_by_pk(
      pk_columns: { id: $id }
      _set: {
        organization_id: $organizationId
        event_id: $eventId
        from_name: $fromName
        from_email: $fromEmail
        bill_to_name: $billToName
        bill_to_email: $billToEmail
        bill_to_phone_number: $billToPhoneNumber
        invoice_number: $invoiceNumber
        invoice_date: $invoiceDate
        invoice_due_date: $invoiceDueDate
        invoice_type: $invoiceType
        invoice_line_items: $lineItems
        invoice_amount: $invoiceAmount
        tax_percentage: $invoiceTaxPercentage
        service_fee_percentage: $invoiceServiceFeePercentage
        description: $description
        total_amount: $totalAmount
        total_tax: $totalTax
        total_service_fee: $totalServiceFee
        grand_total_amount: $grandTotalAmount
        last_updated_by: $last_updated_by
      }
    ) {
      id
    }
  }
`;

export const DELETE_INVOICES_BY_PK = gql`
  mutation DeleteOrganizationUser($id: Int!) {
    delete_invoices_by_pk(id: $id) {
      id
    }
  }
`;

export const INVOICE_SEND_EMAIL_SMS = gql`
  mutation SendEmailSmsInvoice($data: sendInvoiceInput!) {
    invoice_send_email(sendInvoiceInput: $data) {
      message
    }
  }
`;
