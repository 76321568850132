interface InputProps {
    label: string;
    error?: string;
    type?: string;
    name: string;
    required?: boolean;
    placeholder?: string;
    value?: string | number;
    onChange?: (value: number | undefined) => void; // Ensure it expects a number
    disabled?: boolean; // Add disabled prop
}

export default function PriceInput({
                                       label,
                                       error,
                                       name,
                                       type = "text",
                                       placeholder,
                                       required,
                                       value,
                                       onChange,
                                       disabled = false, // Default to not disabled
                                       ...rest
                                   }: InputProps) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (disabled) return; // Prevent change if disabled
        const numericValue = parseFloat(e.target.value); // Convert string to number
        if (onChange) {
            onChange(isNaN(numericValue) ? undefined : numericValue); // Handle empty or invalid values
        }
    };

    return (
        <div>
            <label htmlFor={name} className="block text-sm font-medium text-gray-700">
                {label} {required && <span className="text-red-500">*</span>}
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                    type="number"
                    name={name}
                    id={name}
                    className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-input-500 focus:ring-input-500 sm:text-sm"
                    placeholder={placeholder || "0.00"}
                    value={value !== undefined ? value : ""} // Ensure value is controlled
                    onChange={handleChange} // Handle change
                    min={0}
                    step="0.01"
                    aria-describedby="price-currency"
                    disabled={disabled} // Pass disabled prop to input
                    {...rest}
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <span className="text-gray-500 sm:text-sm" id="price-currency">
            USD
          </span>
                </div>
            </div>
            {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
        </div>
    );
}
