import {
  BlogPostPosEdit,
  blogPostPosEditAction,
  blogPostPosEditLoader,
} from "./blogpostPosEdit";
import { ActionFunctionArgs, redirect } from "react-router-dom";
import { createApolloClient } from "../../../providers/ApolloClientFactory";
import toast from "react-hot-toast";
import { DELETE_BLOG_POS_POST } from "./queries_pos_mutations";
import {
  BlogPostPosCreate,
  blogpostPosCreateAction,
} from "./blogpostPosCreate";

const blogPostPosDelete = async ({ params }: ActionFunctionArgs) => {
  const { id } = params;
  const client = createApolloClient();
  try {
    await client.mutate({
      mutation: DELETE_BLOG_POS_POST,
      variables: { id },
    });
    toast.success("Blog Post Deleted Successfully");
    return redirect(`/dashboard`);
  } catch {
    toast.error("Failed To Delete Blog Post");
  }
};

export const blogpostPosRouter = {
  path: "/blogpost-pos",
  id: "blogpost-pos-root",
  children: [
    {
      path: ":id/edit",
      id: "blogpost-pos-edit",
      element: <BlogPostPosEdit />,
      loader: blogPostPosEditLoader,
      action: blogPostPosEditAction,
    },
    {
      path: "create",
      id: "blogpost-pos-create",
      element: <BlogPostPosCreate />,
      action: blogpostPosCreateAction,
    },
    {
      path: ":id/delete",
      id: "blogpost-pos-delete",
      action: blogPostPosDelete,
    },
  ],
};
