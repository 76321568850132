import { GraphQLClient } from "graphql-request";
import type { Moment } from "moment";
import moment from "moment";

import {
  useFetchers,
  useRouteLoaderData,
} from "react-router-dom";
import { useEffect, useState } from "react";

const HASURA_ENDPOINT = import.meta.env.VITE_SERVICE_HASURA_ENDPOINT;

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const usdFormat = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(" ");
}

export const formatDateForApi = (date: Moment) => {
  return moment.tz(date, timezone).format("YYYY-MM-DDTHH:mm:ss[+00:00]");
};

export const capitalize = (s: string | undefined) =>
  s
    ? s
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
    : "";

export const snakeCaseToSentenceCase = (s: string) =>
  s
    .split("_")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");

/**
 * Converts a byte array to a "URL safe" base64 string
 * @param u8 byte array to convert
 */
export function safeBase64(u8: Uint8Array) {
  const rawBase64 = btoa(String.fromCharCode(...u8));
  return rawBase64.replace(/\+/g, "-").replace(/\//g, "_");
}

export const createGraphqlClient = () => {
  const token = localStorage.getItem("ron-auth");
  return new GraphQLClient(HASURA_ENDPOINT, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const useRouteLoaderDataWithFetcher = <T>(id: string) => {
  const loaderData = useRouteLoaderData(id);
  const fetchers = useFetchers();
  const [data, setData] = useState(loaderData);
  useEffect(() => {
    if (fetchers && fetchers[0] && fetchers[0].data) {
      setData(fetchers[0].data);
    }
  }, [fetchers]);
  return data as T;
};

export const modulesLabel: any = {
  orders: "Orders",
  reporting: "Point of Sale Reporting",
  accessReporting: "Access Reporting",
  webCheckoutReporting: "Web Checkout Reporting",
  ticketingReporting: "Ticketing Reporting",
  organizations: "Organizations",
  payment_processors: "Payment Processors",
  events: "Events",
  vendors: "Vendors",
  locations: "Locations",
  items: "Items",
  items_modifiers: "Items Modifiers",
  menus: "Menus",
  discounts: "Discounts",
  promocode: "PromoCode",
  invoices: "Invoices",
  users: "Users",
  roles: "Roles",
  attendees: "Attendees",
  cashless_assets: "Cashless Assets",
  tickets: "Tickets",
  devices: "Devices",
  dropdown: "Dropdown",
};

export const modules: any = {
  dropdown: [
    {
      label: "Event Dropdown",
      value: "EVENT_DROPDOWN_ACCESS",
    },
  ],
  orders: [
    {
      label: "Access",
      value: "ORDERS_VIEW",
    },
  ],
  reporting: [
    {
      label: "Financial Report",
      value: "REPORTS_ACCESS_FINANCIAL",
    },
    {
      label: "Net Hourly Report",
      value: "REPORTS_ACCESS_NET_HOURLY",
    },
    {
      label: "Cumulative Hourly Report",
      value: "REPORTS_ACCESS_CUMULATIVE_HOURLY",
    },
    {
      label: "Clerk Report",
      value: "REPORTS_ACCESS_CLERK",
    },
    {
      label: "Transaction Report",
      value: "REPORTS_ACCESS_TRANSACTION",
    },
    {
      label: "Deferred Report",
      value: "REPORTS_ACCESS_DEFERRED",
    },
    {
      label: "Event Report",
      value: "REPORTS_ACCESS_EVENT",
    },
    {
      label: "Location Report",
      value: "REPORTS_ACCESS_LOCATION",
    },
    {
      label: "Vendor Report",
      value: "REPORTS_ACCESS_VENDOR",
    },
    {
      label: "Vendor Type Report",
      value: "REPORTS_ACCESS_VENDOR_TYPE",
    },
    {
      label: "Product Summary Report",
      value: "REPORTS_ACCESS_PRODUCT_SUMMARY",
    },
    {
      label: "Product Mix Report",
      value: "REPORTS_ACCESS_PROUCT_MIX",
    },
    {
      label: "GL Account Report",
      value: "REPORTS_ACCESS_GL_ACCOUNT",
    },
    {
      label: "Discounts Report",
      value: "REPORTS_ACCESS_DISCOUNTS",
    },
    {
      label: "Promo Codes Report",
      value: "REPORTS_ACCESS_PROMO_CODES",
    },
    {
      label: "Cash Balance Report",
      value: "REPORTS_ACCESS_CASH_BALANCE",
    },
    {
      label: "Promo Balance Report",
      value: "REPORTS_ACCESS_PROMO_BALANCE",
    },
  ],
  ticketingReporting: [
    {
      label: "Ticket Search",
      value: "TICKET_SEARCH",
    },
    {
      label: "Financial Report",
      value: "REPORTS_ACCESS_FINANCIAL",
    },
    {
      label: "Net Hourly Report",
      value: "REPORTS_ACCESS_NET_HOURLY",
    },
    {
      label: "Cumulative Hourly Report",
      value: "REPORTS_ACCESS_CUMULATIVE_HOURLY",
    },
    {
      label: "Transaction Report",
      value: "REPORTS_ACCESS_TRANSACTION",
    },
    {
      label: "Event Report",
      value: "REPORTS_ACCESS_EVENT",
    },
    {
      label: "Product Mix Report",
      value: "REPORTS_ACCESS_PRODUCT_MIX",
    },
    {
      label: "Promo Codes Report",
      value: "REPORTS_ACCESS_PROMO_CODES",
    },
  ],
  accessReporting: [
    {
      label: "Access Scans Report",
      value: "REPORTS_ACCESS_ACCESS_SCANS",
    },
  ],
  webCheckoutReporting: [
    {
      label: "Web Checkout Report",
      value: "REPORTS_WEB_CHECKOUT",
    },
  ],
  events: [
    {
      label: "View",
      value: "EVENT_VIEW",
    },
    {
      label: "Create/Edit",
      value: "EVENT_EDIT",
    },
    {
      label: "Delete",
      value: "EVENT_DELETE",
    },
  ],
  vendors: [
    {
      label: "View",
      value: "VENDOR_VIEW",
    },
    {
      label: "Create/Edit",
      value: "VENDOR_EDIT",
    },
    {
      label: "Delete",
      value: "VENDOR_DELETE",
    },
  ],
  locations: [
    {
      label: "View",
      value: "LOCATION_VIEW",
    },
    {
      label: "Create/Edit",
      value: "LOCATION_EDIT",
    },
    {
      label: "Delete",
      value: "LOCATION_DELETE",
    },
  ],
  items: [
    {
      label: "View",
      value: "ITEM_VIEW",
    },
    {
      label: "Create/Edit",
      value: "ITEM_EDIT",
    },
    {
      label: "Delete",
      value: "ITEM_DELETE",
    },
  ],
  items_modifiers: [
    {
      label: "View",
      value: "ITEM_MODIFIER_VIEW",
    },
    {
      label: "Create/Edit",
      value: "ITEM_MODIFIER_EDIT",
    },
    {
      label: "Delete",
      value: "ITEM_MODIFIER_DELETE",
    },
  ],
  menus: [
    {
      label: "View",
      value: "MENU_VIEW",
    },
    {
      label: "Create/Edit",
      value: "MENU_EDIT",
    },
    {
      label: "Delete",
      value: "MENU_DELETE",
    },
  ],
  discounts: [
    {
      label: "View",
      value: "DISCOUNT_VIEW",
    },
    {
      label: "Create/Edit",
      value: "DISCOUNT_EDIT",
    },
    {
      label: "Delete",
      value: "DISCOUNT_DELETE",
    },
  ],
  users: [
    {
      label: "View",
      value: "USER_VIEW",
    },
    {
      label: "Create/Edit",
      value: "USER_EDIT",
    },
    {
      label: "Delete",
      value: "USER_DELETE",
    },
  ],
  roles: [
    {
      label: "View",
      value: "ROLE_VIEW",
    },
    {
      label: "Create/Edit",
      value: "ROLE_EDIT",
    },
    {
      label: "Delete",
      value: "ROLE_DELETE",
    },
  ],
  attendees: [
    {
      label: "View",
      value: "ATTENDEE_VIEW",
    },
    {
      label: "Create/Edit",
      value: "ATTENDEE_EDIT",
    },
  ],
  cashless_assets: [
    {
      label: "View",
      value: "CASHLESS_ASSET_VIEW",
    },
    {
      label: "Create/Edit",
      value: "CASHLESS_ASSET_EDIT",
    },
  ],

  invoices: [
    {
      label: "View",
      value: "INVOICES_VIEW",
    },
    {
      label: "Create/Edit",
      value: "INVOICES_EDIT",
    },
    {
      label: "Delete",
      value: "INVOICES_DELETE",
    },
  ],
};

export const adminModules: any = {
  dropdown: [
    {
      label: "Organization Dropdown",
      value: "ORGANIZATION_DROPDOWN_ACCESS",
    },
    {
      label: "Event Dropdown",
      value: "EVENT_DROPDOWN_ACCESS",
    },
  ],
  orders: [
    {
      label: "Access",
      value: "ORDERS_VIEW",
    },
    {
      label: "Edit",
      value: "ORDERS_EDIT",
    },
    {
      label: "Delete",
      value: "ORDERS_DELETE",
    },
  ],
  reporting: [
    {
      label: "Financial Report",
      value: "REPORTS_ACCESS_FINANCIAL",
    },
    {
      label: "Net Hourly Report",
      value: "REPORTS_ACCESS_NET_HOURLY",
    },
    {
      label: "Cumulative Hourly Report",
      value: "REPORTS_ACCESS_CUMULATIVE_HOURLY",
    },
    {
      label: "Clerk Report",
      value: "REPORTS_ACCESS_CLERK",
    },
    {
      label: "Transaction Report",
      value: "REPORTS_ACCESS_TRANSACTION",
    },
    {
      label: "Deferred Report",
      value: "REPORTS_ACCESS_DEFERRED",
    },
    {
      label: "Event Report",
      value: "REPORTS_ACCESS_EVENT",
    },
    {
      label: "Location Report",
      value: "REPORTS_ACCESS_LOCATION",
    },
    {
      label: "Vendor Report",
      value: "REPORTS_ACCESS_VENDOR",
    },
    {
      label: "Vendor Type Report",
      value: "REPORTS_ACCESS_VENDOR_TYPE",
    },
    {
      label: "Product Summary Report",
      value: "REPORTS_ACCESS_PRODUCT_SUMMARY",
    },
    {
      label: "Product Mix Report",
      value: "REPORTS_ACCESS_PROUCT_MIX",
    },
    {
      label: "GL Account Report",
      value: "REPORTS_ACCESS_GL_ACCOUNT",
    },
    {
      label: "Discounts Report",
      value: "REPORTS_ACCESS_DISCOUNTS",
    },
    {
      label: "Promo Codes Report",
      value: "REPORTS_ACCESS_PROMO_CODES",
    },
    {
      label: "Cash Balance Report",
      value: "REPORTS_ACCESS_CASH_BALANCE",
    },
    {
      label: "Promo Balance Report",
      value: "REPORTS_ACCESS_PROMO_BALANCE",
    },
  ],
  ticketingReporting: [
    {
      label: "Ticket Search",
      value: "TICKET_SEARCH",
    },
    {
      label: "Financial Report",
      value: "REPORTS_ACCESS_FINANCIAL",
    },
    {
      label: "Net Hourly Report",
      value: "REPORTS_ACCESS_NET_HOURLY",
    },
    {
      label: "Cumulative Hourly Report",
      value: "REPORTS_ACCESS_CUMULATIVE_HOURLY",
    },
    {
      label: "Transaction Report",
      value: "REPORTS_ACCESS_TRANSACTION",
    },
    {
      label: "Event Report",
      value: "REPORTS_ACCESS_EVENT",
    },
    {
      label: "Product Mix Report",
      value: "REPORTS_ACCESS_PRODUCT_MIX",
    },
    {
      label: "Promo Codes Report",
      value: "REPORTS_ACCESS_PROMO_CODES",
    },
  ],
  accessReporting: [
    {
      label: "Access Scans Report",
      value: "REPORTS_ACCESS_ACCESS_SCANS",
    },
  ],
  webCheckoutReporting: [
    {
      label: "Web Checkout Report",
      value: "REPORTS_WEB_CHECKOUT",
    },
  ],
  organizations: [
    {
      label: "View",
      value: "ORGANIZATIONS_VIEW",
    },
    {
      label: "Create/Edit",
      value: "ORGANIZATIONS_EDIT",
    },
    {
      label: "Delete",
      value: "ORGANIZATIONS_DELETE",
    },
  ],
  payment_processors: [
    {
      label: "View",
      value: "PAYMENT_PROCESSSORS_VIEW",
    },
    {
      label: "Create/Edit",
      value: "PAYMENT_PROCESSSORS_EDIT",
    },
    {
      label: "Delete",
      value: "PAYMENT_PROCESSSORS_DELETE",
    },
  ],
  events: [
    {
      label: "View",
      value: "EVENT_VIEW",
    },
    {
      label: "Create/Edit",
      value: "EVENT_EDIT",
    },
    {
      label: "Delete",
      value: "EVENT_DELETE",
    },
  ],
  vendors: [
    {
      label: "View",
      value: "VENDOR_VIEW",
    },
    {
      label: "Create/Edit",
      value: "VENDOR_EDIT",
    },
    {
      label: "Delete",
      value: "VENDOR_DELETE",
    },
  ],
  locations: [
    {
      label: "View",
      value: "LOCATION_VIEW",
    },
    {
      label: "Create/Edit",
      value: "LOCATION_EDIT",
    },
    {
      label: "Delete",
      value: "LOCATION_DELETE",
    },
  ],
  items: [
    {
      label: "View",
      value: "ITEM_VIEW",
    },
    {
      label: "Create/Edit",
      value: "ITEM_EDIT",
    },
    {
      label: "Delete",
      value: "ITEM_DELETE",
    },
  ],
  items_modifiers: [
    {
      label: "View",
      value: "ITEM_MODIFIER_VIEW",
    },
    {
      label: "Create/Edit",
      value: "ITEM_MODIFIER_EDIT",
    },
    {
      label: "Delete",
      value: "ITEM_MODIFIER_DELETE",
    },
  ],
  menus: [
    {
      label: "View",
      value: "MENU_VIEW",
    },
    {
      label: "Create/Edit",
      value: "MENU_EDIT",
    },
    {
      label: "Delete",
      value: "MENU_DELETE",
    },
  ],
  discounts: [
    {
      label: "View",
      value: "DISCOUNT_VIEW",
    },
    {
      label: "Create/Edit",
      value: "DISCOUNT_EDIT",
    },
    {
      label: "Delete",
      value: "DISCOUNT_DELETE",
    },
  ],
  promocode: [
    {
      label: "View",
      value: "PROMOCODE_VIEW",
    },
    {
      label: "Create/Edit",
      value: "PROMOCODE_EDIT",
    },
    {
      label: "Delete",
      value: "PROMOCODE_DELETE",
    },
  ],
  users: [
    {
      label: "View",
      value: "USER_VIEW",
    },
    {
      label: "Create/Edit",
      value: "USER_EDIT",
    },
    {
      label: "Delete",
      value: "USER_DELETE",
    },
  ],
  roles: [
    {
      label: "View",
      value: "ROLE_VIEW",
    },
    {
      label: "Create/Edit",
      value: "ROLE_EDIT",
    },
    {
      label: "Delete",
      value: "ROLE_DELETE",
    },
  ],
  attendees: [
    {
      label: "View",
      value: "ATTENDEE_VIEW",
    },
    {
      label: "Create/Edit",
      value: "ATTENDEE_EDIT",
    },
    {
      label: "Delete",
      value: "ATTENDEE_DELETE",
    },
  ],
  cashless_assets: [
    {
      label: "View",
      value: "CASHLESS_ASSET_VIEW",
    },
    {
      label: "Create/Edit",
      value: "CASHLESS_ASSET_EDIT",
    },
    {
      label: "Delete",
      value: "CASHLESS_ASSET_DELETE",
    },
  ],
  invoices: [
    {
      label: "View",
      value: "INVOICES_VIEW",
    },
    {
      label: "Create/Edit",
      value: "INVOICES_EDIT",
    },
    {
      label: "Delete",
      value: "INVOICES_DELETE",
    },
  ],
  devices: [
    {
      label: "View",
      value: "EVENT_VIEW",
    },
  ],
};

export const promoMethods = [
  {
    label: "Qr Code",
    value: "qr_code",
  },
  {
    label: "Key In",
    value: "key_in",
  },
];

export const promoCodeTypes = [
  {
    label: "Percentage Off",
    value: "percentage_off",
  },
  {
    label: "Amount Off",
    value: "amount_off",
  },
];

export const triggerRulesOptions = [
  {
    label: "No Trigger Rules",
    value: null,
  },
  {
    label: "Spend Threshold",
    value: "spend_threshold",
  },
  {
    label: "Item Quantity",
    value: "item_quantity",
  },
];

export const appliesToOptions = [
  {
    label: "Whole Order",
    value: "whole_order",
  },
  {
    label: "Inclusive Item/Category",
    value: "inclusive_only",
  },
];

export const capitalLetter = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
export const alphabet = `${capitalLetter}0123456789`;
export const qrAlhabet = `${capitalLetter.toLowerCase()}${alphabet}`;
