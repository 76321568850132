import { organizationStore } from "../../store/organization";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { GET_LOCATIONS } from "./queries";
import {
  Link,
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
  useRevalidator,
} from "react-router-dom";
import { Location, LocationMenus } from "../../types/location";
import { Badge } from "../../components/Badge";
import { useEffect, useRef, useState } from "react";
import { eventStore } from "../../store/event";
import { userStore } from "../../store/user";
import moment from "moment/moment";
import { usePermissions } from "../auth/api";
import {
  ColDef,
  GridApi,
  SizeColumnsToContentStrategy,
} from "ag-grid-community";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Input } from "../../components/inputs/Input";
import { PrimaryButton, SecondaryButton } from "../../components/Button";
import { AgGridReact } from "ag-grid-react";
import LocationImportCSVModal from "../../components/importCsv/LocationImportCSVModal";
import Switch from "react-switch";

const client = createApolloClient();
const { getState } = userStore;
const { getState: getOrganizationState } = organizationStore;
const { getState: getEventStore } = eventStore;

const buildSearch = (search: string) => [
  {
    name: {
      _ilike: `%${search}%`,
    },
  },
  {
    location_type: {
      _ilike: `%${search}%`,
    },
  },
];

type CellRenderer = (params: {
  value:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  data: Location;
}) => JSX.Element | null;
const createCellRenderer = (
  permission: boolean,
  linkFunc: (params: { data: Location }) => string
): CellRenderer => {
  return (params) => {
    if (params.value === undefined) {
      return null;
    }
    return (
      <>
        {permission ? (
          <Link to={linkFunc(params)}>
            <Badge>{params.value}</Badge>
          </Link>
        ) : (
          <Badge>{params.value}</Badge>
        )}
      </>
    );
  };
};
const createMultipleLinksCellRenderer = (
  permission: boolean,
  linkFunc: (locationMenu: LocationMenus) => string
): CellRenderer => {
  return (params) => {
    const menus = params.data?.location_menus?.map((locationMenus, index) => (
      <span key={index}>
        {permission ? (
          <Link to={linkFunc({ ...locationMenus, menus: locationMenus.menu })}>
            <Badge>{locationMenus.menu.name}</Badge>
          </Link>
        ) : (
          <Badge>{locationMenus.menu.name}</Badge>
        )}
        {index < (params?.data?.location_menus?.length ?? 0) - 1 && " "}
      </span>
    ));
    return <>{menus}</>;
  };
};
export const locationsListLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const search = url.searchParams.get("search");
  const rawOrganizationId = getOrganizationState().organizationId;
  const organizationId = parseInt(rawOrganizationId !== null ? rawOrganizationId.toString() : '', 10);
  const eventId = getEventStore().eventId;
  const user = getState().user;
  console.log({ user });

  let where: any = {
    _or: search ? buildSearch(search) : undefined,
  };
  if (organizationId && organizationId !== 0) {
    where = {
      ...where,
      event: {
        organization_id: {
          _eq: organizationId,
        },
      },
    };
  }
  if (eventId) {
    where = {
      ...where,
      event_id: {
        _eq: eventId,
      },
    };
  } else if (user?.events) {
    where = {
      ...where,
      event_id: {
        _in: user?.events,
      },
    };
  }
  if (user?.vendors) {
    where = {
      ...where,
      vendor_id: {
        _in: user?.vendors,
      },
    };
  }

  const { data } = await client.query({
    query: GET_LOCATIONS,
    variables: {
      where,
    },
  });
  return data.locations;
};

export const LocationList = () => {
  const { editCreatePermission } = usePermissions("locations");
  const { editCreatePermission: paymentProcessorEditPermission } =
    usePermissions("payment_processors");
  const { editCreatePermission: menuEditPermission } = usePermissions("menus");
  const { editCreatePermission: eventsEditPermission } =
    usePermissions("events");
  const { editCreatePermission: vendorsEditPermission } =
    usePermissions("vendors");
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const gridRef = useRef<GridApi>();
  const locations = useLoaderData() as Location[];
  const [rowData, setRowData] = useState<Location[]>([]);
  const [isActiveToggle, setIsActiveToggle] = useState(true);
  useEffect(() => {
    const filteredItems = isActiveToggle
        ? locations.filter((location) => location.is_active)
        : locations;
    setRowData(filteredItems);
  }, [locations, isActiveToggle]);
  const generateColDefs = (): ColDef<Location>[] => [
    {
      headerName: "Id",
      valueGetter: (p) => p.data?.id,
      cellRenderer: createCellRenderer(
        editCreatePermission,
        (p) => `/locations/${p.data.id}/edit`
      ),
      filter: "agTextColumnFilter",
      pinned: "left",
    },
    {
      headerName: "Location Name",
      valueGetter: (p) => p.data?.name,
      cellRenderer: createCellRenderer(
        editCreatePermission,
        (p) => `/locations/${p.data.id}/edit`
      ),
      filter: "agTextColumnFilter",
      pinned: "left",
    },
    {
      headerName: "Is Active?",
      valueGetter: (p) => p.data?.is_active,
      valueFormatter: (p) => {
        return p.data?.is_active ? "Yes" : "No";
      },
    },
    {
      headerName: "Location Type",
      valueGetter: (p) => p.data?.location_type,
    },
    {
      headerName: "Event Name",
      valueGetter: (p) => p.data?.event?.name,
      cellRenderer: createCellRenderer(
        eventsEditPermission,
        (p) => `/events/${p.data?.event?.id}/edit`
      ),
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Vendor Name",
      valueGetter: (p) => p.data?.vendor?.name,
      cellRenderer: createCellRenderer(
        vendorsEditPermission,
        (p) => `/vendors/${p.data?.vendor?.id}/edit`
      ),
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Payment Processor",
      valueGetter: (p) => p.data?.payment_processor_config?.payment_processor,
      cellRenderer: createCellRenderer(
        paymentProcessorEditPermission,
        (p) =>
          `/payment-processors/${p.data?.payment_processor_config?.id}/edit`
      ),
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Service Fee %",
      valueGetter: (p) => p.data?.digital_surcharge_percentage,
      valueFormatter: (params) => {
        const value = params.value ?? 0;
        return value !== 0 ? `${value}%` : "";
      },
    },
    {
      headerName: "Dynamic Descriptor",
      valueGetter: (p) => p.data?.dynamic_descriptor,
    },
    {
      headerName: "Menu(s) Name",
      valueGetter: (p) => p.data?.location_menus,
      valueFormatter: (params) => {
        console.log(params.value);
        const menus: LocationMenus[] = params.value || [];
        return menus.map((menu) => menu?.menus?.name).join(", ");
      },
      cellRenderer: createMultipleLinksCellRenderer(
        menuEditPermission,
        (locationMenus) => `/menus/${locationMenus?.menus?.id}/edit`
      ),
      filter: false,
    },
    {
      headerName: "Clerks Required?",
      valueGetter: (p) => p.data?.users_required,
      valueFormatter: (p) => {
        return p.data?.users_required ? "Yes" : "No";
      },
    },
    {
      headerName: "Clerk Passwords Required?",
      valueGetter: (p) => p.data?.users_password_required,
      valueFormatter: (p) => {
        return p.data?.users_password_required ? "Yes" : "No";
      },
    },
    {
      headerName: "Updated By",
      valueGetter: (p) => p.data?.user?.username,
    },
    {
      headerName: "Created Date",
      valueGetter: (p) => p.data?.created_at,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss a") : "";
      },
    },
    {
      headerName: "Updated Date",
      valueGetter: (p) => p.data?.updated_at,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss a") : "";
      },
    },
  ];
  const colDefs: ColDef<Location>[] = generateColDefs();
  const rowHeight = 50;
  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: "fitCellContents",
  };
  const handleExportCSV = () => {
    if (gridRef.current) {
      const timestamp = new Date().toISOString().replace(/[-:]/g, "");
      const params = {
        fileName: `Locations_${timestamp}.csv`,
        columnSeparator: ",",
      };
      gridRef.current.exportDataAsCsv(params);
    }
  };
  const gridOptions = {
    enableCellTextSelection: true,
  };
  return (
    <div style={{ height: "75vh" }}>
      <div
        className={"ag-theme-quartz"}
        style={{ width: "100%", height: "100%" }}
      >
        <div className=" grid gap-2 grid-cols-6">
          <Breadcrumbs/>
          <div className="col-span-1 col-start-4 mt-3 flex flex-col items-center">
            <span className="block text-sm font-medium text-gray-500">Active Only</span>
            <Switch
                checked={isActiveToggle}
                onChange={() => setIsActiveToggle((prev) => !prev)}
                onColor="#EE2E30"
                onHandleColor="#FFFFFF"
                handleDiameter={18}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={15}
                width={48}
            />
          </div>
          <div className="col-span-2 col-start-5 mt-2">
            <Input
                name="search"
                placeholder={`Search Locations`}
                onChange={(s) => {
                  navigate(
                      s && typeof s === "string" && s.length > 0
                          ? `/locations/list?search=${s}`
                          : `/locations/list`
                  );
                  revalidator.revalidate();
                }}
            />
          </div>
          <div className="py-2 col-span-2 col-start-7">
            {/*<LocationImportCSVModal*/}
            {/*  // user={user}*/}
            {/*  onConfirm={() => {*/}
            {/*    // fetcher.submit(*/}
            {/*    //   {},*/}
            {/*    //   {*/}
            {/*    //     method: "delete",*/}
            {/*    //     action: `/menus/${menu.id}/delete`,*/}
            {/*    //   }*/}
            {/*    // );*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {({ setOpen }) => (*/}
            {/*    <PrimaryButton onClick={() => setOpen(true)}>*/}
            {/*      Import CSV*/}
            {/*    </PrimaryButton>*/}
            {/*  )}*/}
            {/*</LocationImportCSVModal>*/}
            <SecondaryButton onClick={handleExportCSV}>
              Export to CSV
            </SecondaryButton>
            {editCreatePermission && (
                <PrimaryButton onClick={() => navigate(`/locations/create`)}>
                  Create
                </PrimaryButton>
            )}
          </div>
        </div>
        <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            rowHeight={rowHeight}
            autoSizeStrategy={autoSizeStrategy}
            pagination={true}
            gridOptions={gridOptions}
            paginationPageSize={100}
            onGridReady={(params) => (gridRef.current = params.api)}
        />
      </div>
    </div>
  );
};
