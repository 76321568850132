import { gql } from "@apollo/client";

export const Queries_mutations_pos = gql`
  query blogPos($reference_type: String!) {
    dashboard_reference(
      where: { reference_type: { _eq: $reference_type } }
      order_by: { updated_at: desc }
    ) {
      id
      reference_value
      reference_label
      reference_links
      reference_images
      created_at
      updated_at
    }
  }
`;

export const BLOGS_POS_BY_ID = gql`
  query blogPos($id: Int!) {
    dashboard_reference_by_pk(id: $id) {
      id
      reference_value
      reference_label
      reference_links
      reference_images
      created_at
      updated_at
    }
  }
`;

export const POS_BLOGS_POS = gql`
  query blogPos($reference_type: String!) {
    dashboard_reference(where: { reference_type: { _eq: $reference_type } }) {
      id
      reference_value
      reference_links
      reference_images
      updated_at
    }
  }
`;

export const UPDATE_BLOGS_POS_BY_ID = gql`
  mutation UpdateDashboardPosRefByID(
    $id: Int!
    $reference_label: String
    $reference_value: String
  ) {
    update_dashboard_reference_by_pk(
      pk_columns: { id: $id }
      _set: {
        reference_label: $reference_label
        reference_value: $reference_value
      }
    ) {
      id
    }
  }
`;

export const CREATE_BLOG_POS_POST = gql`
  mutation createBlogPostPos(
    $reference_value: String
    $reference_label: String
  ) {
    insert_dashboard_reference_one(
      object: {
        reference_type: "posapp_blog_entry"
        reference_value: $reference_value
        reference_label: $reference_label
        is_active: true
      }
    ) {
      id
    }
  }
`;

export const DELETE_BLOG_POS_POST = gql`
  mutation DeleteBlogPostPos($id: Int!) {
    delete_dashboard_reference_by_pk(id: $id) {
      id
    }
  }
`;
