import { gql } from "@apollo/client";

export const Queries_mutations = gql`
  query blog($reference_type: String!) {
    dashboard_reference(where: {reference_type: {_eq: $reference_type}}, order_by: {updated_at: desc}) {
      id
      reference_value
      reference_label
      reference_links
      reference_images
      created_at
      updated_at
    }
  }
`;

export const BLOGS_BY_ID = gql`
  query blog($id: Int!) {
    dashboard_reference_by_pk(id: $id ) {
      id
      reference_value
      reference_label
      reference_links
      reference_images
      created_at
      updated_at
    }
  }
`;

export const POS_BLOGS = gql`
  query blog($reference_type: String!) {
    dashboard_reference(where: { reference_type: { _eq: $reference_type } }) {
      id
      reference_value
      reference_links
      reference_images
      updated_at
    }
  }
`;

export const UPDATE_BLOGS_BY_ID = gql`
mutation UpdateDashboardRefByID($id: Int!, $reference_label: String, $reference_value: String) {
  update_dashboard_reference_by_pk(pk_columns: {id: $id}, _set: {reference_label: $reference_label, reference_value: $reference_value}) {
    id
  }
}
`;

export const CREATE_BLOG_POST = gql`
mutation createBlogPost($reference_value: String, $reference_label: String) {
  insert_dashboard_reference_one(object: {reference_type: "dashboard_blog_entry", reference_value: $reference_value, reference_label: $reference_label, is_active: true}) {
    id
  }
}
`;

export const DELETE_BLOG_POST = gql`
  mutation DeleteBlogPost($id: Int!) {
    delete_dashboard_reference_by_pk(id: $id) {
      id
    }
  }
`;
