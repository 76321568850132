import { useLoaderData, LoaderFunctionArgs } from "react-router-dom";
import { CASH_BALANCE_REPORT } from "./fragment";
import { formatData, colDefs } from "./helpers";
import { ReportFilters } from "../../components/ReportFilters";
import ReportHeader from "../../components/ReportHeader";
import { createApolloClient } from "../../../../providers/ApolloClientFactory";
import { organizationStore } from "../../../../store/organization";
import { userStore } from "../../../../store/user";
import { CashBalanceReportRow } from "./type";
import { eventStore } from "../../../../store/event";
import { AgGridReact } from "ag-grid-react";
import { GridApi, SizeColumnsToContentStrategy } from "ag-grid-community";
import { useRef } from "react";
import { SecondaryButton } from "../../../../components/Button";

const client = createApolloClient();
const { getState } = userStore;
const { getState: getOrganizationState } = organizationStore;
const { getState: getEventState } = eventStore;

export const cashBalanceReportLoader = async ({
  request,
}: LoaderFunctionArgs) => {
  const rawOrganizationId = getOrganizationState().organizationId;
  const organizationId = parseInt(rawOrganizationId !== null ? rawOrganizationId.toString() : '', 10);
  const { eventId } = getEventState();
  const user = getState().user;
  console.log({ url: request.url });
  const search = new URL(request.url);
  const where = search.searchParams.get("where");

  const vendorWhere = {};
  let whereVariable: any = where
    ? {
        ...JSON.parse(where),
        ...vendorWhere,
      }
    : {};

  if (organizationId && organizationId !== 0) {
    whereVariable = {
      ...whereVariable,
      ...vendorWhere,
      organization_id: {
        _eq: organizationId,
      },
    };
  }

  if (eventId) {
    whereVariable = {
      ...whereVariable,
      ...vendorWhere,
      event_id: {
        _eq: eventId,
      },
    };
  } else if (user?.events) {
    whereVariable = {
      ...whereVariable,
      ...vendorWhere,
      event_id: {
        _in: user?.events,
      },
    };
  }
  console.log({ whereVariable });
  const { data } = await client.query({
    query: CASH_BALANCE_REPORT,
    variables: { where: whereVariable },
  });
  return data.reports_cash_balance || [];
};

export const CashBalanceReport = () => {
  const data = useLoaderData() as CashBalanceReportRow[];
  const rows = formatData(data);
  const tableRows = rows.filter((row) => row.username !== "Total");
  const totalRow = rows.filter((row) => row.username === "Total");
  const gridRef = useRef<GridApi>();
  const rowHeight = 50;
  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: "fitCellContents",
  };
  const handleExportCSV = () => {
    if (gridRef.current) {
      const timestamp = new Date().toISOString().replace(/[-:]/g, "");
      const params = {
        fileName: `CashBalanceLoadedReport_${timestamp}.csv`, // Updated file name with timestamp
        columnSeparator: ",",
      };
      gridRef.current.exportDataAsCsv(params);
    }
  };
  const gridOptions = {
    enableCellTextSelection: true,
  };
  return (
    <>
      <div className="bg-white px-4 pb-1 mb-3 rounded-md">
        <ReportHeader title="Cash Balance Loaded Report" />
        <ReportFilters
          includeDates
          includeTime
          includeUsernames
          includeLocations
          customQueryPaths={{
            dateFrom: "_and[0].created_at_hour",
            dateTo: "_and[1].created_at_hour",
          }}
        />
        <div className="flex">
          <div className="ml-auto">
            <SecondaryButton onClick={handleExportCSV}>
              Export to CSV
            </SecondaryButton>
          </div>
        </div>
      </div>
      <div style={{ height: "60vh", marginTop: "4px" }}>
        <div
          className={"ag-theme-quartz"}
          style={{ width: "100%", height: "100%" }}
        >
          <AgGridReact
            rowData={tableRows}
            columnDefs={colDefs}
            rowHeight={rowHeight}
            autoSizeStrategy={autoSizeStrategy}
            pagination={true}
            gridOptions={gridOptions}
            paginationPageSize={100}
            pinnedBottomRowData={totalRow}
            onGridReady={(params) => (gridRef.current = params.api)} // Set GridApi reference
          />
        </div>
      </div>
    </>
  );
};
