import {LoaderFunctionArgs, useLoaderData, useLocation} from "react-router-dom";
import {PRODUCT_MIX_REPORT, PRODUCT_MIX_REPORT_COUNT} from "./fragment";
import { formatData, colDefs } from "./helpers";
import { ReportFilters } from "../../components/ReportFilters";
import ReportHeader from "../../components/ReportHeader";
import { userStore } from "../../../../store/user";
import { createApolloClient } from "../../../../providers/ApolloClientFactory";
import { organizationStore } from "../../../../store/organization";
import { ProductMixReportRow } from "./type";
import React, {useEffect, useRef, useState} from "react";
import { eventStore } from "../../../../store/event";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";
import { SecondaryButton, ToggleButton } from "../../../../components/Button";
import { AgGridReact } from "ag-grid-react";
import { GridApi, SizeColumnsToContentStrategy } from "ag-grid-community";
import {TransactionReportRow} from "../transactions-report/type";
import {TRANSACTION_REPORT, TRANSACTION_REPORT_COUNT} from "../transactions-report/fragment";
import Loader from "../../../../components/Loader";

const client = createApolloClient();
const { getState } = userStore;
const { getState: getOrganizationState } = organizationStore;
const { getState: getEventState } = eventStore;
const PAGE_SIZE = 50000;
const DEBOUNCE_DELAY = 100;

const BarChartComponent = ({
  rows,
  selectedDataKey,
}: {
  rows: ProductMixReportRow[];
  selectedDataKey: string;
}) => {
  if (!rows || rows.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-full pb-4">
        <div className="text-gray-500 text-sm">No data to display</div>
      </div>
    );
  }

  const filteredData = rows.filter(item => item !== null && item !== undefined);
  const sortedData = filteredData.sort((a, b) => {
    const key = selectedDataKey as keyof ProductMixReportRow;
    const aValue = a[key] as number;
    const bValue = b[key] as number;
    return bValue - aValue;
  });
  const customTickFormatter = (value: number) => {
    return value.toString();
  };

  // Check if selectedDataKey is equal to total_units_sold
  const isTotalUnitsSold = selectedDataKey === "total_units_sold";
  const limitedData = sortedData.slice(0, 20);
  const usdFormatter = (value: number) => {
    return `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
  };
  function formatDataKey(key: string) {
    return key
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  const maxProductTypeLength = Math.max(
    ...limitedData.map((item) => (item.pos_name ? item.pos_name.length : 0))
  );
  const angleInRadians = (Math.abs(-30) * Math.PI) / 180;
  const textHeight = Math.sin(angleInRadians) * maxProductTypeLength * 10;
  const xAxisHeight = textHeight > 50 ? textHeight : 50;
  return (
    <div className="flex bg-white rounded-md flex-row items-center w-full">
      <div className="overflow-x-auto">
        <div className="flex flex-col">
          <div className="inline-block min-w-full align-middle">
            <BarChart
              width={1200}
              height={350 + xAxisHeight}
              data={limitedData}
              margin={{ top: 20, right: 10, left: 10, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="pos_name"
                tick={{
                  fontSize: "0.875rem",
                  fontWeight: 600,
                  fill: "#4a5568",
                }}
                {...(sortedData.length > 5 && {
                  interval: 0,
                  angle: -35,
                  textAnchor: "end",
                  height: xAxisHeight,
                })}
              />
              <YAxis
                width={100}
                tickFormatter={
                  isTotalUnitsSold ? customTickFormatter : usdFormatter
                } // Use custom formatter for total_units_sold
                tick={{
                  fontSize: "0.875rem",
                  fontWeight: 600,
                  fill: "#4a5568",
                }}
              />
              <Tooltip
                contentStyle={{
                  fontSize: "0.875rem",
                  fontWeight: 600,
                  backgroundColor: "#fff",
                  border: "1px solid #e2e8f0",
                  color: "#4a5568",
                }}
                formatter={
                  isTotalUnitsSold ? customTickFormatter : usdFormatter
                }
              />
              <Bar
                dataKey={selectedDataKey}
                fill="#B71C1C"
                name={formatDataKey(selectedDataKey)}
              />
            </BarChart>
          </div>
        </div>
      </div>
    </div>
  );
};
export const ProductMixReport = () => {
  const [data, setData] = useState<ProductMixReportRow[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const formattedData = formatData(data);
  const chartRows = formattedData.filter((row) => row.pos_name !== "Total");
  const tableRows = formattedData.filter((row) => row.pos_name !== "Total");
  const [selectedDataKey, setSelectedDataKey] = useState("total_net_sales");
  const handleDataKeyChange = (newDataKey: string) => {
    setSelectedDataKey(newDataKey);
  };
  const gridRef = useRef<GridApi>();
  const rowHeight = 50;
  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: "fitCellContents",
  };
  const handleExportCSV = () => {
    if (gridRef.current) {
      const timestamp = new Date().toISOString().replace(/[-:]/g, "");
      const params = {
        fileName: `ProductMixSalesReport_${timestamp}.csv`,
        columnSeparator: ",",
      };
      gridRef.current.exportDataAsCsv(params);
    }
  };
  const gridOptions = {
    enableCellTextSelection: true,
  };
  const location = useLocation();
  const fetchTimeoutRef = useRef<any>(null);

  useEffect(() => {
    let isMounted = true;

    const debouncedFetch = () => {
      clearTimeout(fetchTimeoutRef.current);
      fetchTimeoutRef.current = setTimeout(fetchTransactions, DEBOUNCE_DELAY);
    };
    const fetchTransactions = async () => {
      setIsLoading(true);
      try {
        const rawOrganizationId = getOrganizationState().organizationId;
        const organizationId = parseInt(rawOrganizationId !== null ? rawOrganizationId.toString() : '', 10);
        const { eventId } = getEventState();
        const user = getState().user;

        const search = new URLSearchParams(location.search);
        const where = search.get("where");
        let whereVariable: any = where ? JSON.parse(where) : {};
        if (organizationId && organizationId !== 0) {
          if (user?.vendors) {
            whereVariable = {
              ...whereVariable,
              vendor_id: {
                _in: user?.vendors,
              },
            };
          }

          whereVariable = {
            ...whereVariable,
            organization_id: {
              _eq: organizationId,
            },
          };
        }

        if (eventId) {
          whereVariable = {
            ...whereVariable,
            event_id: {
              _eq: eventId,
            },
          };
        } else if (user?.events) {
          whereVariable = {
            ...whereVariable,
            event_id: {
              _in: user?.events,
            },
          };
        }

        const { data: totalData } = await client.query({
          query: PRODUCT_MIX_REPORT_COUNT,
          variables: {
            where: whereVariable,
            limit: 0,
          },
        });
        const totalCount = totalData.total.aggregate.count;
        console.log(totalCount);
        let offset = 0;
        let chunkData: ProductMixReportRow[] = [];

        while (offset < totalCount) {
          const { data: chunk } = await client.query({
            query: PRODUCT_MIX_REPORT,
            variables: {
              where: whereVariable,
              limit: PAGE_SIZE,
              offset: offset,
            },
          });
          chunkData = [...chunkData, ...(chunk.reports_product_mix || [])];
          offset += PAGE_SIZE;
        }

        if (isMounted) {
          setData(chunkData);
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };
    debouncedFetch();
    return () => {
      isMounted = false;
      clearTimeout(fetchTimeoutRef.current);
    };
  }, [location.search]);


  return (
    <>
      <div className="bg-white px-4 pb-1 mb-3 rounded-md">
        <ReportHeader title="Product Mix Report" />
        <ReportFilters
          includeDates
          includeTime
          includeLocations
          includeVendors
          includeUsernames
          customQueryPaths={{
            dateFrom: "_and[0].transaction_hour",
            dateTo: "_and[1].transaction_hour",
          }}
        />
        <div className="flex">
          <div className="ml-auto">
            <SecondaryButton onClick={handleExportCSV}>
              Export to CSV
            </SecondaryButton>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "stretch", flexWrap: "wrap" }}>
        <ToggleButton
          isSelected={selectedDataKey === "total_net_sales"}
          onClick={() => handleDataKeyChange("total_net_sales")}
        >
          Total Net Sales
        </ToggleButton>
        <ToggleButton
          isSelected={selectedDataKey === "total_gross_sales"}
          onClick={() => handleDataKeyChange("total_gross_sales")}
        >
          Total Gross Sales
        </ToggleButton>
        <ToggleButton
          isSelected={selectedDataKey === "total_units_sold"}
          onClick={() => handleDataKeyChange("total_units_sold")}
        >
          Total Units Sold
        </ToggleButton>
      </div>
      <BarChartComponent rows={chartRows} selectedDataKey={selectedDataKey} />
      <div style={{ height: "60vh", marginTop: "4px" }}>
        <div
          className={"ag-theme-quartz"}
          style={{ width: "100%", height: "100%" }}
        >
          {isLoading ? (
              <Loader />
          ) : (
          <AgGridReact
            rowData={tableRows}
            columnDefs={colDefs}
            rowHeight={rowHeight}
            autoSizeStrategy={autoSizeStrategy}
            pagination={true}
            paginationPageSize={100}
            gridOptions={gridOptions}
            // pinnedBottomRowData={totalRow}
            onGridReady={(params) => (gridRef.current = params.api)} // Set GridApi reference
          />
        )}
        </div>
      </div>
    </>
  );
};
