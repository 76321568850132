import { Fragment, ReactNode, useState } from "react";
import { Link, useLocation, useRouteError } from "react-router-dom";
import { Dialog, Menu, Transition, Disclosure } from "@headlessui/react";
import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  Bars3BottomLeftIcon,
  XMarkIcon,
  CurrencyDollarIcon,
  CreditCardIcon,
  UserGroupIcon,
  CpuChipIcon,
  DevicePhoneMobileIcon,
  MapPinIcon,
  BuildingStorefrontIcon,
  ShoppingCartIcon,
  PencilSquareIcon,
  DocumentTextIcon,
  ShoppingBagIcon,
  LockClosedIcon,
  GiftTopIcon,
  BanknotesIcon,
  TicketIcon,
} from "@heroicons/react/24/outline";
import { OrganizationSelect } from "./inputs/OrganizationSelect";
import { UserIcon } from "@heroicons/react/20/solid";
import { classNames, capitalize } from "../utils";
import { useLogout, usePermissions } from "../modules/auth/api";
import { useUserStore } from "../store/user";
import { EventSliderSelector } from "./inputs/EventSliderSelector";

const buildNavigation = (permissions: any, path: string) => {
  // console.log({ organizations: !(permissions["events"]?.length > 0) });
  const pointOfSaleReports = [
    {
      name: "Financial Report",
      href: "/reports/financial-report",
      current: path === "/reports/financial-report",
      hidden: !permissions["reporting"]?.find(
        (report: { label: string; value: string }) =>
          report?.value === "REPORTS_ACCESS_FINANCIAL"
      )?.value,
    },
    {
      name: "Net Hourly Report",
      href: "/reports/net-hourly-report",
      current: path === "/reports/net-hourly-report",
      hidden: !permissions["reporting"]?.find(
        (report: { label: string; value: string }) =>
          report?.value === "REPORTS_ACCESS_NET_HOURLY"
      )?.value,
    },
    {
      name: "Cumulative Hourly Report",
      href: "/reports/cumulative-net-hourly-report",
      current: path === "/reports/cumulative-net-hourly-report",
      hidden: !permissions["reporting"]?.find(
        (report: { label: string; value: string }) =>
          report?.value === "REPORTS_ACCESS_CUMULATIVE_HOURLY"
      )?.value,
    },
    {
      name: "Clerk Report",
      href: "/reports/clerk-report",
      current: path === "/reports/clerk-report",
      hidden: !permissions["reporting"]?.find(
        (report: { label: string; value: string }) =>
          report?.value === "REPORTS_ACCESS_CLERK"
      )?.value,
    },
    {
      name: "Transaction Report",
      href: "/reports/transaction-report",
      current: path === "/reports/transaction-report",
      hidden: !permissions["reporting"]?.find(
        (report: { label: string; value: string }) =>
          report?.value === "REPORTS_ACCESS_TRANSACTION"
      )?.value,
    },
    {
      name: "Deferred Report",
      href: "/reports/deferred-transaction-report",
      current: path === "/reports/deferred-transaction-report",
      hidden: !permissions["reporting"]?.find(
        (report: { label: string; value: string }) =>
          report?.value === "REPORTS_ACCESS_DEFERRED"
      )?.value,
    },
    {
      name: "Event Sales Report",
      href: "/reports/event-report",
      current: path === "/reports/event-report",
      hidden: !permissions["reporting"]?.find(
        (report: { label: string; value: string }) =>
          report?.value === "REPORTS_ACCESS_EVENT"
      )?.value,
    },
    {
      name: "Location Sales Report",
      href: "/reports/location-report",
      current: path === "/reports/location-report",
      hidden: !permissions["reporting"]?.find(
        (report: { label: string; value: string }) =>
          report?.value === "REPORTS_ACCESS_LOCATION"
      )?.value,
    },
    {
      name: "Vendor Sales Report",
      href: "/reports/vendor-report",
      current: path === "/reports/vendor-report",
      hidden: !permissions["reporting"]?.find(
        (report: { label: string; value: string }) =>
          report?.value === "REPORTS_ACCESS_VENDOR"
      )?.value,
    },
    {
      name: "Vendor Type Sales Report",
      href: "/reports/vendor-type-report",
      current: path === "/reports/vendor-type-report",
      hidden: !permissions["reporting"]?.find(
        (report: { label: string; value: string }) =>
          report?.value === "REPORTS_ACCESS_VENDOR_TYPE"
      )?.value,
    },
    {
      name: "Product Summary Report",
      href: "/reports/product-mix-summary-report",
      current: path === "/reports/product-mix-summary-report",
      hidden: !permissions["reporting"]?.find(
        (report: { label: string; value: string }) =>
          report?.value === "REPORTS_ACCESS_PRODUCT_SUMMARY"
      )?.value,
    },
    {
      name: "Product Mix Report",
      href: "/reports/product-mix-report",
      current: path === "/reports/product-mix-report",
      hidden: !permissions["reporting"]?.find(
        (report: { label: string; value: string }) =>
          report?.value === "REPORTS_ACCESS_PROUCT_MIX"
      )?.value,
    },
    {
      name: "GL Account Report",
      href: "/reports/gl-account-report",
      current: path === "/reports/gl-account-report",
      hidden: !permissions["reporting"]?.find(
          (report: { label: string; value: string }) =>
              report?.value === "REPORTS_ACCESS_GL_ACCOUNT"
      )?.value,
    },
    {
      name: "Discounts Report",
      href: "/reports/discounts-report",
      current: path === "/reports/discounts-report",
      hidden: !permissions["reporting"]?.find(
          (report: { label: string; value: string }) =>
              report?.value === "REPORTS_ACCESS_DISCOUNTS"
      )?.value,
    },
    {
      name: "Promo Codes Report",
      href: "/reports/promo-codes-report",
      current: path === "/reports/promo-codes-report",
      hidden: !permissions["reporting"]?.find(
          (report: { label: string; value: string }) =>
              report?.value === "REPORTS_ACCESS_PROMO_CODES"
      )?.value,
    },
    {
      name: "Cash Balance Report",
      href: "/reports/cash-balance-report",
      current: path === "/reports/cash-balance-report",
      hidden: !permissions["reporting"]?.find(
        (report: { label: string; value: string }) =>
          report?.value === "REPORTS_ACCESS_CASH_BALANCE"
      )?.value,
    },
    {
      name: "Promo Balance Report",
      href: "/reports/promo-balance-report",
      current: path === "/reports/promo-balance-report",
      hidden: !permissions["reporting"]?.find(
        (report: { label: string; value: string }) =>
          report?.value === "REPORTS_ACCESS_PROMO_BALANCE"
      )?.value,
    },
  ];

  const ticketingReports = [
    {
      name: "Ticket Search",
      icon: TicketIcon,
      current: path.includes("/tickets"),
      href: "/tickets/list",
      hidden: !permissions["ticketingReporting"]?.find(
          (report: { label: string; value: string }) =>
              report?.value === "TICKET_SEARCH"
      )?.value,
    },
    {
      name: "Financial Report",
      href: "/reports/financial-report-ticketing",
      current: path === "/reports/financial-report-ticketing",
      hidden: !permissions["ticketingReporting"]?.find(
          (report: { label: string; value: string }) =>
              report?.value === "REPORTS_ACCESS_FINANCIAL"
      )?.value,
    },
    {
      name: "Net Hourly Report",
      href: "/reports/net-hourly-report-ticketing",
      current: path === "/reports/net-hourly-report-ticketing",
      hidden: !permissions["ticketingReporting"]?.find(
          (report: { label: string; value: string }) =>
              report?.value === "REPORTS_ACCESS_NET_HOURLY"
      )?.value,
    },
    {
      name: "Cumulative Hourly Report",
      href: "/reports/cumulative-net-hourly-report-ticketing",
      current: path === "/reports/cumulative-net-hourly-report-ticketing",
      hidden: !permissions["ticketingReporting"]?.find(
          (report: { label: string; value: string }) =>
              report?.value === "REPORTS_ACCESS_CUMULATIVE_HOURLY"
      )?.value,
    },
    {
      name: "Transaction Report",
      href: "/reports/transaction-report-ticketing",
      current: path === "/reports/transaction-report-ticketing",
      hidden: !permissions["ticketingReporting"]?.find(
          (report: { label: string; value: string }) =>
              report?.value === "REPORTS_ACCESS_TRANSACTION"
      )?.value,
    },
    {
      name: "Event Sales Report",
      href: "/reports/event-report-ticketing",
      current: path === "/reports/event-report-ticketing",
      hidden: !permissions["ticketingReporting"]?.find(
          (report: { label: string; value: string }) =>
              report?.value === "REPORTS_ACCESS_EVENT"
      )?.value,
    },
    {
      name: "Product Mix Report",
      href: "/reports/product-mix-report-ticketing",
      current: path === "/reports/product-mix-report-ticketing",
      hidden: !permissions["ticketingReporting"]?.find(
          (report: { label: string; value: string }) =>
              report?.value === "REPORTS_ACCESS_PRODUCT_MIX"
      )?.value,
    },
    {
      name: "Promo Codes Report",
      href: "/reports/promo-codes-report-ticketing",
      current: path === "/reports/promo-codes-report-ticketing",
      hidden: !permissions["ticketingReporting"]?.find(
          (report: { label: string; value: string }) =>
              report?.value === "REPORTS_ACCESS_PROMO_CODES"
      )?.value,
    },
  ];

  const ticketingReportsChildren = ticketingReports.filter(
      ({ hidden }) => !hidden
  );
  const pointOfSaleReportsChildren = pointOfSaleReports.filter(
    ({ hidden }) => !hidden
  );
  const accessReports = [
    {
      name: "Access Scans Report",
      href: "/reports/access-scans-report",
      current: path === "/reports/access-scans-report",
      hidden: !permissions["accessReporting"]?.find(
        (report: { label: string; value: string }) =>
          report?.value === "REPORTS_ACCESS_ACCESS_SCANS"
      )?.value,
    },
  ];
  const accessReportsChildren = accessReports.filter(({ hidden }) => !hidden);

  const webCheckoutReports = [
    {
      name: "Web Transactions Report",
      href: "/reports/web-checkout-report",
      current: path === "/reports/web-checkout-report",
      hidden: !permissions["webCheckoutReporting"]?.find(
          (report: { label: string; value: string }) =>
              report?.value === "REPORTS_WEB_CHECKOUT"
      )?.value,
    },
  ];
  const webCheckoutReportsChildren = webCheckoutReports.filter(({ hidden }) => !hidden);
  return [
    {
      name: "Dashboard",
      icon: HomeIcon,
      current: path === "/",
      href: "/",
      hidden: false,
    },
    {
      name: "Orders",
      icon: ShoppingBagIcon,
      current: path.includes("/orders"),
      href: "/orders/list",
      hidden: !permissions["orders"]?.length,
    },
    {
      name: "Point of Sale Reports",
      icon: ChartBarIcon,
      current: false,
      children: pointOfSaleReportsChildren,
      hidden: !permissions["reporting"]?.length,
    },
    {
      name: "Ticketing Reports",
      icon: ChartBarIcon,
      current: false,
      children: ticketingReportsChildren,
      hidden: !permissions["ticketingReporting"]?.length,
    },
    {
      name: "Access Reports",
      icon: ChartBarIcon,
      current: false,
      children: accessReportsChildren,
      hidden: !permissions["accessReporting"]?.length,
    },
    {
      name: "Web Checkout Reports",
      icon: ChartBarIcon,
      current: false,
      children: webCheckoutReportsChildren,
      hidden: !permissions["webCheckoutReporting"]?.length,
    },
    {
      name: "Organizations",
      icon: FolderIcon,
      current: path.includes("/organizations"),
      href: "/organizations/list",
      hidden: !permissions["organizations"]?.length,
    },
    {
      name: "Payment Processors",
      icon: CreditCardIcon,
      current: path.includes("/payment-processors"),
      href: "/payment-processors/list",
      hidden: !permissions["payment_processors"]?.length,
    },
    {
      name: "Events",
      icon: CalendarIcon,
      current: path.includes("/events"),
      href: "/events/list",
      hidden: !permissions["events"]?.length,
    },
    {
      name: "Vendors",
      icon: BuildingStorefrontIcon,
      current: path.includes("/vendors"),
      href: "/vendors/list",
      hidden: !permissions["vendors"]?.length,
    },
    {
      name: "Locations",
      icon: MapPinIcon,
      current: path.includes("/locations"),
      href: "/locations/list",
      hidden: !permissions["locations"]?.length,
    },
    {
      name: "Menus",
      icon: DocumentTextIcon,
      current: path.includes("/menus"),
      href: "/menus/list",
      hidden: !permissions["menus"]?.length,
    },
    {
      name: "Items",
      icon: ShoppingCartIcon,
      current: path.includes("/items"),
      href: "/items/list",
      hidden: !permissions["items"]?.length,
    },
    {
      name: "Item Modifiers",
      icon: PencilSquareIcon,
      current: path.includes("/item-modifiers"),
      href: "/item-modifiers/list",
      hidden: !permissions["items_modifiers"]?.length,
    },
    {
      name: "Discounts",
      icon: CurrencyDollarIcon,
      current: path.includes("/discounts"),
      href: "/discounts/list",
      hidden: !permissions["discounts"]?.length,
    },
    {
      name: "Promo Code",
      icon: GiftTopIcon,
      current: path.includes("/promocode"),
      href: "/promocode/list",
      hidden: !permissions["promocode"]?.length,
    },
    {
      name: "Users",
      icon: UsersIcon,
      current: path.includes("/users"),
      href: "/users/list",
      hidden: !permissions["users"]?.length,
    },
    {
      name: "Roles",
      icon: LockClosedIcon,
      current: path.includes("/roles"),
      href: "/roles/list",
      hidden: !permissions["roles"]?.length,
    },
    {
      name: "Attendees",
      icon: UserGroupIcon,
      current: path.includes("/attendees"),
      href: "/attendees/list",
      hidden: !permissions["attendees"]?.length,
    },
    {
      name: "Cashless Assets",
      icon: CpuChipIcon,
      current: path.includes("/rfid_assets"),
      href: "/rfid-assets/list",
      hidden: !permissions["cashless_assets"]?.length,
    },
    {
      name: "Devices",
      icon: DevicePhoneMobileIcon,
      current: path.includes("/devices"),
      href: "/devices/list",
      hidden: !permissions["devices"]?.length,
    },
    {
      name: "Invoices",
      icon: BanknotesIcon,
      current: path.includes("/invoices"),
      href: "/invoices/list",
      hidden: !permissions["invoices"]?.length,
    },
  ].filter(({ hidden }) => !hidden);
};

export default function Sidebar({ children }: { children: ReactNode }) {
  const error = useRouteError();
  const user = useUserStore((state) => state.user);
  // console.log({ user });
  const [logout] = useLogout();
  const location = useLocation();
  const { permissions } = usePermissions();
  // console.log({ permissions });
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigation = buildNavigation(permissions ?? "", location.pathname);
  const { moduleWisePermission } = usePermissions("dropdown");
  // console.log({ moduleWisePermission });
  const eventPermission = moduleWisePermission?.find(
    (modulePermission: { label: string; value: string }) =>
      modulePermission.value === "EVENT_DROPDOWN_ACCESS"
  );
  const organizationPermission = moduleWisePermission?.find(
    (modulePermission: { label: string; value: string }) =>
      modulePermission.value === "ORGANIZATION_DROPDOWN_ACCESS"
  );
  return (
    <div className="min-h-screen">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-primary-700 pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>

                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5 pb-4">
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w- justify-centerauto"
                      src="/static/logo_color_no_bg.png"
                      alt="Ronin"
                    />
                  </div>
                  <div className="mt-5 flex flex-grow flex-col">
                    <nav
                      className="flex-1 space-y-1 bg-white px-2"
                      aria-label="Sidebar"
                    >
                      {navigation.map((item) =>
                        !item.children ? (
                          <div key={item.name}>
                            <Link
                              to={item.href}
                              className={classNames(
                                item.current
                                  ? "bg-gray-100 text-gray-900"
                                  : "bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                                "group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md"
                              )}
                              onClick={() => setSidebarOpen(false)}
                            >
                              <item.icon
                                className={classNames(
                                  item.current
                                    ? "text-gray-500"
                                    : "text-gray-400 group-hover:text-gray-500",
                                  "mr-3 flex-shrink-0 h-6 w-6"
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                            </Link>
                          </div>
                        ) : (
                          <Disclosure
                            as="div"
                            defaultOpen={location.pathname.includes("/reports")}
                            key={item.name}
                            className="space-y-1"
                          >
                            {({ open }) => (
                              <>
                                <Disclosure.Button
                                  className={classNames(
                                    item.current
                                      ? "bg-gray-100 text-gray-900"
                                      : "bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                                    "group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500"
                                  )}
                                >
                                  <item.icon
                                    className="mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"
                                  />
                                  <span className="flex-1">{item.name}</span>
                                  <svg
                                    className={classNames(
                                      open
                                        ? "text-gray-400 rotate-90"
                                        : "text-gray-300",
                                      "ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400"
                                    )}
                                    viewBox="0 0 20 20"
                                    aria-hidden="true"
                                  >
                                    <path
                                      d="M6 6L14 10L6 14V6Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </Disclosure.Button>
                                <Disclosure.Panel className="space-y-1">
                                  {item.children.map((subItem) => (
                                    <Disclosure.Button
                                      key={subItem.name}
                                      as="a"
                                      href={subItem.href}
                                      className={classNames(
                                        subItem.current
                                          ? "bg-gray-100 text-gray-900"
                                          : "bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                                        "group flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium"
                                      )}
                                      onClick={() => setSidebarOpen(false)}
                                    >
                                      {subItem.name}
                                    </Disclosure.Button>
                                  ))}
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        )
                      )}
                    </nav>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
        <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5 pb-4">
          <div className="flex flex-shrink-0 items-center justify-center px-4">
            <img
              className="h-40 w-auto justify-self-center"
              src="/static/logo-standard-compressed.svg"
              alt="Ronin"
            />
          </div>
          <div className="mt-5 flex flex-grow flex-col">
            <nav
              className="flex-1 space-y-1 bg-white px-2"
              aria-label="Sidebar"
            >
              {navigation.map((item) =>
                !item.children ? (
                  <div key={item.name}>
                    <Link
                      to={item.href}
                      className={classNames(
                        item.current
                          ? "bg-gray-100 text-gray-900"
                          : "bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                        "group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md"
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.current
                            ? "text-gray-500"
                            : "text-gray-400 group-hover:text-gray-500",
                          "mr-3 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  </div>
                ) : (
                  <Disclosure
                    as="div"
                    key={item.name}
                    defaultOpen={location.pathname.includes("/reports")}
                    className="space-y-1"
                  >
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={classNames(
                            item.current
                              ? "bg-gray-100 text-gray-900"
                              : "bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-primary-600"
                          )}
                        >
                          <item.icon
                            className="mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          <span className="flex-1">{item.name}</span>
                          <svg
                            className={classNames(
                              open
                                ? "text-gray-400 rotate-90"
                                : "text-gray-300",
                              "ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400"
                            )}
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                          >
                            <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                          </svg>
                        </Disclosure.Button>
                        <Disclosure.Panel className="space-y-1">
                          {item.children.map((subItem) => (
                            <Disclosure.Button
                              key={subItem.name}
                              className={classNames(
                                subItem.current
                                  ? "bg-gray-100 text-gray-900"
                                  : "bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                                "group flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium"
                              )}
                            >
                              <Link to={subItem.href}>{subItem.name}</Link>
                            </Disclosure.Button>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )
              )}
            </nav>
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col md:pl-64 min-h-screen">
        <div className="h-auto  sticky top-0 z-10 flex flex-shrink-1 bg-white shadow py-2">
          <button
            type="button"
            className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex flex-1 flex-wrap pl-2 pr-9 selectbox-wrap">
            {organizationPermission ? (
              <div className="ml-4 flex items-center grow md:ml-1 w-100">
                {/* {!error && user && permissions === "admin" && ( */}
                <OrganizationSelect />
                {/* )} */}
              </div>
            ) : (
              ""
            )}
            <div className="ml-4 flex items-center grow md:ml-1 w-100">
              {!error && user && eventPermission && <EventSliderSelector />}
            </div>

            <div className="ml-4 flex justify-end items-center md:ml-1 w-100">
              {/* Profile dropdown */}
              <Menu as="div" className="relative ml-3">
                <div>
                  <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                    {capitalize(user?.username)}
                    <span className="sr-only">Open user menu</span>
                    <UserIcon className="h-8 w-8 rounded-full" />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                      <div
                        className="block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                        onClick={logout}
                      >
                        Sign Out
                      </div>
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
        <main className="h-[calc(100vh_-_64px)]">{children}</main>
      </div>
    </div>
  );
}
