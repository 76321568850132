import { gql } from "@apollo/client";

export const INVOICES_LIST_QUERY = gql`
  query InvoicesList($where: invoices_bool_exp) {
    invoices(where: $where, order_by: { id: desc }) {
      bill_to_email
    bill_to_name
    bill_to_phone_number
    created_at
    description
    event_id
    invoice_event {
        name
        }
    invoice_user {
        username
        }
    from_email
    from_name
    grand_total_amount
    id
    invoice_amount
    invoice_date
    invoice_due_date
    invoice_line_items
    invoice_number
    invoice_type
    last_updated_by
    organization_id
    reference_id
    service_fee_percentage
    status
    tax
    tax_percentage
    total_amount
    total_service_fee
    total_tax
    updated_at
    }
  }
`;

export const INVOICE_BY_PK = gql`
  query InvoiceById($id: Int!) {
    invoices_by_pk(id: $id) {
        bill_to_email
        bill_to_name
        bill_to_phone_number
        created_at
        description
        event_id
        from_email
        from_name
        grand_total_amount
        id
        invoice_amount
        invoice_date
        invoice_due_date
        invoice_line_items
        invoice_number
        invoice_type
        last_updated_by
        organization_id
        reference_id
        service_fee_percentage
        status
        tax
        tax_percentage
        total_amount
        total_service_fee
        total_tax
        updated_at
        processor_reference_id
        billing_first_name
        billing_last_name
        billing_address_1
        billing_address_2
        billing_city
        billing_state
        billing_zipcode
        last_four_of_card
        card_type
        paid_date
    }
  }
`;

export const GET_LIST_EVENTS_BY_ORGANIZATION = gql`
  query EventsByOrganization($where: events_bool_exp!) {
    events(order_by: { name: asc }, where: $where) {
      id
      name
      location_zones
    }
  }
`;
