import React from "react";
import GaugeComponent from "react-gauge-component";
import { hourColumns } from "../../../reports/point-of-sale/financial-hourly-report/helpers";
import { FinancialHourlyReportRow } from "../../../reports/point-of-sale/financial-cumulative-hourly-report/type";

type MergedChartData = {
    hour: string;
    totalNetSalesRecord1: number;
    date: string;
};

type Stats = {
    min: number;
    max: number;
    average: number;
};

const FuelGauge = ({ rows }: { rows: FinancialHourlyReportRow[] }) => {
    if (!rows || rows.length === 0) {
        return (
            <div className="flex flex-col items-center justify-center h-full pb-4">
                <div className="text-gray-500 text-sm">No data to display</div>
            </div>
        );
    }
    const [day1] = rows;
    const day1ChartData = hourColumns.map((column) => ({
        hour: column.header ? String(column.header) : "",
        totalNetSales: Number(
            (day1 as Record<string, any>)[
                (column as { accessorKey: keyof FinancialHourlyReportRow }).accessorKey
                ]
        ),
        date: day1.display_date || "",
    }));

    const mergedChartData: MergedChartData[] = day1ChartData.map((entry) => ({
        hour: entry.hour,
        totalNetSalesRecord1: entry.totalNetSales,
        date: entry.date,
    }));

    // Calculate stats
    const calculateStats = (data: MergedChartData[]): Stats => {
        const filteredData = data.filter((row) => row.totalNetSalesRecord1 > 0);
        if (filteredData.length === 0) {
            return { min: 0, max: 0, average: 0 };
        }
        const values = filteredData.map((row) => row.totalNetSalesRecord1);
        const min = Math.min(...values);
        const max = Math.max(...values);
        const average = values.reduce((sum, value) => sum + value, 0) / values.length;

        return { min, max, average };
    };

    const stats = calculateStats(mergedChartData);
    return (
        <div>
            <GaugeComponent
                value={stats.average}
                minValue={stats.min === stats.max ? 0 : stats.min}
                maxValue={stats.max === 0 ? 1 : stats.max}
                labels={{
                    valueLabel: {
                        formatTextValue: (value) => '$' + value,
                        style: {
                            fontSize: "50rem",
                            fontWeight: 600,
                            fill: "#4a5568",
                        },
                    },
                    tickLabels: {
                        type: 'inner',
                        defaultTickValueConfig: {
                            formatTextValue: (value) => '$' + value,
                            maxDecimalDigits: 2,
                            style: {
                                fontSize: "0.875rem",
                                fontWeight: 600,
                                fill: "#4a5568",
                            },
                        },
                        ticks: [
                            { value: stats.min },
                            { value: stats.max },
                        ],
                    },
                }}
                arc={{
                    width: 0.2,
                    gradient: true,
                    subArcs: stats.min === stats.max
                        ? [{ limit: stats.max, color: "#5BE12C" }]
                        : [
                            { limit: (stats.max - stats.min) / 4 + stats.min, color: "#EA4228" }, // First quarter
                            { limit: (stats.max - stats.min) / 2 + stats.min, color: "#F58B19" }, // Second quarter
                            { limit: ((stats.max - stats.min) * 3) / 4 + stats.min, color: "#F5CD19" }, // Third quarter
                            { limit: stats.max, color: "#5BE12C" }, // Last quarter
                        ],
                }}
                pointer={{
                    color: '#000000',
                    length: 0.80,
                    width: 15,
                    elastic: true,
                    type: "arrow",
                }}
                type="semicircle"
            />
        </div>
    );
};

export default FuelGauge;
