import { useLoaderData, LoaderFunctionArgs } from "react-router-dom";
import { REPORTS_FINANCIAL } from "./fragment";
import { colDefs, formatData } from "./helpers";
import { ReportFilters } from "../../components/ReportFilters";
import ReportHeader from "../../components/ReportHeader";
import { createApolloClient } from "../../../../providers/ApolloClientFactory";
import { organizationStore } from "../../../../store/organization";
import { userStore } from "../../../../store/user";
import { FinancialReportRow } from "./type";
import React, { useRef, useState } from "react";
import { eventStore } from "../../../../store/event";
import { BarChart, XAxis, YAxis, Tooltip, Bar, CartesianGrid } from "recharts";
import { SecondaryButton, ToggleButton } from "../../../../components/Button";
import { GridApi, SizeColumnsToContentStrategy } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";

const client = createApolloClient();
const { getState } = userStore;
const { getState: getOrganizationState } = organizationStore;
const { getState: getEventState } = eventStore;

export const financialReportTicketingLoader = async ({
  request,
}: LoaderFunctionArgs) => {
  const rawOrganizationId = getOrganizationState().organizationId;
  const organizationId = parseInt(rawOrganizationId !== null ? rawOrganizationId.toString() : '', 10);
  const { eventId } = getEventState();
  const user = getState().user;

  const search = new URL(request.url);
  const where = search.searchParams.get("where");

  const vendorWhere = user?.vendors
    ? {
        _and: {
          vendor_id: {
            _in: user?.vendors,
          },
        },
      }
    : {};

  let whereVariable: any = where
    ? {
        ...JSON.parse(where),
        ...vendorWhere,
      }
    : {};

  if (organizationId && organizationId !== 0) {
    whereVariable = {
      ...whereVariable,
      ...vendorWhere,
      organization_id: {
        _eq: organizationId,
      },
    };
  }

  if (eventId) {
    whereVariable = {
      ...whereVariable,
      ...vendorWhere,
      event_id: {
        _eq: eventId,
      },
    };
  } else if (user?.events) {
    whereVariable = {
      ...whereVariable,
      ...vendorWhere,
      event_id: {
        _in: user?.events,
      },
    };
  }

  const { data } = await client.query({
    query: REPORTS_FINANCIAL,
    variables: { where: whereVariable },
  });
  return data.reports_financial_ticketing || [];
};

const BarChartComponent = ({
                             rows,
                             selectedDataKey,
                           }: {
  rows: FinancialReportRow[];
  selectedDataKey: string;
}) => {
  if (!rows || rows?.length === 0) {
    return (
        <div className="flex flex-col items-center justify-center h-full pb-4">
          <div className="text-gray-500 text-sm">No data to display</div>
        </div>
    );
  }

  // Filter out null or undefined items
  const filteredData = rows.filter(item => item !== null && item !== undefined);

  const limitedData = filteredData.slice(0, 20);
  const reversedData = limitedData.reverse();
  const usdFormatter = (value: number) => {
    return `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
  };

  const dateFormatter = (value: string) => {
    const date = moment.utc(value); // Parse as UTC
    return date.format("ddd, MMM DD, YYYY");
  };

  function formatDataKey(key: string) {
    return key
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
  }

  // Calculate max date length safely
  const maxDateLength = Math.max(
      ...reversedData.map((item) => item.transaction_date?.length || 0)
  );

  const angleInRadians = (Math.abs(-30) * Math.PI) / 180;
  const textHeight = Math.sin(angleInRadians) * maxDateLength * 9.5;
  const xAxisHeight = textHeight > 50 ? textHeight : 50;

  return (
      <div className="flex bg-white rounded-md flex-row items-center w-full">
        <div className="overflow-x-auto">
          <div className="flex flex-col">
            <div className="inline-block min-w-full align-middle">
              <BarChart
                  width={1200}
                  height={350 + xAxisHeight}
                  data={reversedData}
                  margin={{ top: 20, right: 10, left: 10, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="transaction_date"
                    tickFormatter={dateFormatter}
                    tick={{
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      fill: "#4a5568",
                    }}
                    {...(reversedData.length > 5 && {
                      interval: 0,
                      angle: -35,
                      textAnchor: "end",
                      height: xAxisHeight,
                    })}
                />
                <YAxis
                    width={100}
                    tickFormatter={usdFormatter}
                    tick={{
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      fill: "#4a5568",
                    }}
                />
                <Tooltip
                    contentStyle={{
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      backgroundColor: "#fff",
                      border: "1px solid #e2e8f0",
                      color: "#4a5568",
                    }}
                    formatter={usdFormatter}
                />
                <Bar
                    dataKey={selectedDataKey}
                    fill="#B71C1C"
                    name={formatDataKey(selectedDataKey)}
                />
              </BarChart>
            </div>
          </div>
        </div>
      </div>
  );
};

export const FinancialReportTicketing = () => {
  const data = useLoaderData() as FinancialReportRow[];
  const formattedData = formatData(data);
  const chartRows = formattedData.filter(
    (row) => row.transaction_date !== "Total"
  );
  const totalRow = formattedData.filter(
    (row) => row.transaction_date === "Total"
  );
  const tableRows = formattedData.filter(
    (row) => row.transaction_date !== "Total"
  );
  const [selectedDataKey, setSelectedDataKey] = useState("total_net_sales");
  const handleDataKeyChange = (newDataKey: string) => {
    setSelectedDataKey(newDataKey);
  };
  const gridRef = useRef<GridApi>();
  const rowHeight = 50;
  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: "fitCellContents",
  };
  const handleExportCSV = () => {
    if (gridRef.current) {
      const timestamp = new Date().toISOString().replace(/[-:]/g, "");
      const params = {
        fileName: `TicketingFinancialReport${timestamp}.csv`,
        columnSeparator: ",",
        allColumns: true,
      };
      gridRef.current.exportDataAsCsv(params);
    }
  };
  const gridOptions = {
    enableCellTextSelection: true,
  };
  return (
    <>
      <div className="bg-white px-4 pb-1 mb-3 rounded-md">
        <ReportHeader title="Ticketing Financial Report" />
        <ReportFilters
          includeDateOnly
          includeDates
          includeVendors
          includeDatesStartOfYear
          includeLocations
          customQueryPaths={{
            dateFrom: "_and[0].transaction_date",
            dateTo: "_and[1].transaction_date",
          }}
        />
        <div className="flex">
          <div className="ml-auto">
            <SecondaryButton onClick={handleExportCSV}>
              Export to CSV
            </SecondaryButton>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "stretch", flexWrap: "wrap" }}>
        <ToggleButton
          isSelected={selectedDataKey === "total_net_sales"}
          onClick={() => handleDataKeyChange("total_net_sales")}
        >
          Total Net Sales
        </ToggleButton>
        <ToggleButton
          isSelected={selectedDataKey === "total_gross_sales"}
          onClick={() => handleDataKeyChange("total_gross_sales")}
        >
          Total Gross Sales
        </ToggleButton>
        <ToggleButton
          isSelected={selectedDataKey === "gross_cash_sales"}
          onClick={() => handleDataKeyChange("gross_cash_sales")}
        >
          Gross Cash Sales
        </ToggleButton>
        <ToggleButton
          isSelected={selectedDataKey === "gross_credit_sales"}
          onClick={() => handleDataKeyChange("gross_credit_sales")}
        >
          Gross Credit Sales
        </ToggleButton>
        <ToggleButton
          isSelected={selectedDataKey === "gross_rfid_sales"}
          onClick={() => handleDataKeyChange("gross_rfid_sales")}
        >
          Gross RFID Sales
        </ToggleButton>
        <ToggleButton
          isSelected={selectedDataKey === "gross_cash_balance_sales"}
          onClick={() => handleDataKeyChange("gross_cash_balance_sales")}
        >
          Gross Cash Balance Sales
        </ToggleButton>
        <ToggleButton
          isSelected={selectedDataKey === "gross_promo_balance_sales"}
          onClick={() => handleDataKeyChange("gross_promo_balance_sales")}
        >
          Gross Promo Balance Sales
        </ToggleButton>
        <ToggleButton
          isSelected={selectedDataKey === "gross_token_sales"}
          onClick={() => handleDataKeyChange("gross_token_sales")}
        >
          Gross Token Sales
        </ToggleButton>
      </div>
      <BarChartComponent rows={chartRows} selectedDataKey={selectedDataKey} />
      <div style={{ height: "60vh", marginTop: "4px" }}>
        <div
          className={"ag-theme-quartz"}
          style={{ width: "100%", height: "100%" }}
        >
          <AgGridReact
            rowData={tableRows}
            columnDefs={colDefs}
            rowHeight={rowHeight}
            autoSizeStrategy={autoSizeStrategy}
            pagination={true}
            paginationPageSize={100}
            gridOptions={gridOptions}
            pinnedBottomRowData={totalRow}
            onGridReady={(params) => (gridRef.current = params.api)} // Set GridApi reference
          />
        </div>
      </div>
    </>
  );
};
