import { organizationStore } from "../../store/organization";
import {
  ActionFunctionArgs,
  redirect,
  useLoaderData,
  Form,
} from "react-router-dom";
import toast from "react-hot-toast";
import { FormLayout } from "../../layout/FormLayout";
import { Input } from "../../components/inputs/Input";
import IndeterminateCheckbox from "../../components/inputs/IndeterminantCheckbox";
import PercentageInput from "../../components/inputs/PercentageInput";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import {
  GET_LIST_EVENTS_BY_ORGANIZATION,
  GET_LIST_MENUS,
  GET_LIST_PAYMENT_PROCESSORS_BY_ORGANIZATION,
  GET_LIST_VENDORS_BY_ORGANIZATION,
} from "../../queries";
import { Vendor } from "../../types/vendor";
import { Event } from "../../types/event";
import { PaymentProcessor } from "../../types/paymentProcessor";
import CustomSelect from "../../components/customSelect";
import { Menu } from "../../types/menu";
import { CREATE_LOCATION, CREATE_LOCATION_MENUS } from "./mutations";
import { useEffect, useState } from "react";
import { TagsInput } from "../../components/inputs/TagsInput";
import { userStore } from "../../store/user";
import { GET_LOCATION_TYPE } from "./queries";
import { Categories } from "../../types/item";

const client = createApolloClient();
type MenuIds = string;
const { getState } = organizationStore;
const { getState: getUserStore } = userStore;
export const locationCreateAction = async ({ request }: ActionFunctionArgs) => {
  const user = getUserStore().user;
  const body = await request.formData();
  const tokensString = body.get("redeemable_tokens") as string;
  body.delete("redeemable_tokens");
  const redeemable_tokens =
    tokensString?.length > 0
      ? tokensString.split(",").map((tag) => tag.trim())
      : [];
  const menuIds: MenuIds[] = body.getAll("menuIds") as MenuIds[];

  body.delete("menuIds");
  const bodyData = Object.fromEntries(body);
  try {
    const { data } = await client.mutate({
      mutation: CREATE_LOCATION,
      variables: {
        input: {
          ...bodyData,
          redeemable_tokens: redeemable_tokens || null,
          payment_processor_id: bodyData?.payment_processor_id || null,
          event_id: bodyData?.event_id || null,
          last_updated_by: user?.id,
        },
      },
    });

    if (!(menuIds.length === 1 && menuIds[0].trim() === "")) {
      await client.mutate({
        mutation: CREATE_LOCATION_MENUS,
        variables: {
          objects: menuIds.map((menuId) => ({
            location_id: data.insert_locations_one.id,
            menu_id: menuId,
          })),
        },
      });
    }

    toast.success("Location created");

    return redirect("/locations/list");
  } catch (error) {
    toast.error("Error creating location: " + error);
    return {};
  }
};

export const locationCreateLoader = async () => {
  const organizationId = getState().organizationId;
  const user = getUserStore().user;

  let vendorWhere = {};
  let eventWhere = {};
  let menuWhere: any = {};
  if (user?.vendors) {
    vendorWhere = {
      ...vendorWhere,
      id: {
        _in: user?.vendors,
      },
    };
  }

  if (user?.events) {
    eventWhere = {
      ...eventWhere,
      id: {
        _in: user?.events,
      },
    };
    menuWhere = {
      ...menuWhere,
      event: {
        id: {
          _in: user?.events,
        },
      },
    };
  }

  if (organizationId) {
    vendorWhere = {
      ...vendorWhere,
      organization_id: {
        _eq: organizationId,
      },
    };

    eventWhere = {
      ...eventWhere,
      organization_id: { _eq: organizationId },
      is_active: { _eq: true },
    };
    menuWhere = {
      ...menuWhere,
      event: {
        ...(menuWhere?.event || {}),
        organization_id: {
          _eq: organizationId,
        },
      },
    };
    if (user?.vendors) {
      menuWhere = {
        ...menuWhere,
        vendor_id: {
          _in: user?.vendors,
        },
      };
    }
  }

  const [
    { data: vendors },
    { data: events },
    { data: paymentProcessors },
    { data: menus },
    { data: locations },
  ] = await Promise.all([
    client.query({
      query: GET_LIST_VENDORS_BY_ORGANIZATION,
      variables: {
        where: vendorWhere,
      },
    }),
    client.query({
      query: GET_LIST_EVENTS_BY_ORGANIZATION,
      variables: {
        where: eventWhere,
      },
    }),
    client.query({
      query: GET_LIST_PAYMENT_PROCESSORS_BY_ORGANIZATION,
      variables: {
        where: organizationId
          ? {
              organization_id: {
                _eq: organizationId,
              },
            }
          : {},
      },
    }),
    client.query({
      query: GET_LIST_MENUS,
      variables: {
        where: menuWhere,
      },
    }),
    client.query({
      query: GET_LOCATION_TYPE,
      variables: {
        where: organizationId
          ? {
              organization_id: {
                _in: [0, organizationId],
              },
              is_active: {
                _eq: true,
              },
              reference_type: {
                _eq: "locations_location_type",
              },
            }
          : {},
      },
    }),
  ]);
  return {
    vendors: vendors?.vendors,
    events: events?.events,
    paymentProcessors: paymentProcessors?.payment_processor_config,
    menus: menus?.menus,
    locations: locations?.dashboard_reference,
  };
};

export const LocationCreate = () => {
  const [selectedEvent, setSelectedEvent] = useState<number>();
  const [inputValue, setInputValue] = useState<string>("");
  const [isUserModified, setIsUserModified] = useState<boolean>(false);
  const user = getUserStore().user;
  const admin = user?.role?.display_name === "Admin"
  const { vendors, events, paymentProcessors, menus, locations } =
    useLoaderData() as {
      vendors: Vendor[];
      events: Event[];
      paymentProcessors: PaymentProcessor[];
      menus: Menu[];
      locations: Categories[];
    };
  const [eventZones, setEventZones] = useState<string[]>([]);
  const selectedEventObject = events.find(
    (event) => event.id === selectedEvent
  );
  useEffect(() => {
    if (selectedEvent) {
      const selectedEventObj = events.find(
        (event) => event.id === selectedEvent
      );
      const zones = selectedEventObj?.location_zones || [];
      setEventZones(zones);
    }
  }, [selectedEvent, events]);
  const sortedPaymentProcessors = [...paymentProcessors].sort((a, b) =>
    a.payment_processor.localeCompare(b.payment_processor)
  );
  const [filteredMenus, setFilteredMenus] = useState<Menu[]>(menus);
  useEffect(() => {
    if (selectedEvent) {
      const updatedMenus = menus.filter(
        (menu) => menu.event_id.toString() === selectedEvent.toString()
      );
      setFilteredMenus(updatedMenus);
    } else {
      setFilteredMenus([]);
    }
  }, [selectedEvent, menus]);
  useEffect(() => {
    if (selectedEventObject && !isUserModified) {
      setInputValue(`RPOS* ${selectedEventObject.name}`);
    }
  }, [selectedEventObject]);
  return (
    <Form
      method="post"
      action="/locations/create"
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    >
      <FormLayout>
        <div className="col-span-5">
          <Input label="Name" name="name" required />
        </div>
        <div className="col-span-1">
          <IndeterminateCheckbox
            name="is_active"
            label="Is Active?"
            defaultChecked
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
            title="Location Type"
            name="location_type"
            required
            options={[
              ...(locations?.map((v) => ({
                label: v.reference_label,
                value: v.reference_value,
              })) || []),
            ]}
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
            title="Event"
            name="event_id"
            required
            onChange={(e) => {
              setSelectedEvent(e);
              setIsUserModified(false);
            }}
            options={[
              ...events.map((event) => ({
                value: event.id,
                label: event.name,
              })),
            ]}
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
            title="Zone"
            name="zone"
            options={[
              ...eventZones.map((zone) => ({
                value: zone,
                label: zone,
              })),
            ]}
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
            title="Vendor"
            name="vendor_id"
            required
            options={[
              ...vendors.map((vend) => ({
                value: vend.id,
                label: vend.name,
              })),
            ]}
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
            title="Menus"
            name="menuIds"
            multi
            options={[
              ...filteredMenus.map((menu) => ({
                value: menu.id,
                label: menu.name,
              })),
            ]}
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
            name="payment_processor_id"
            title="Payment Processor"
            options={[
              { label: "PENDING", value: "" },
              ...sortedPaymentProcessors.map((p) => ({
                value: p.id,
                label: p.payment_processor,
              })),
            ]}
          />
        </div>
        <>
          <div className="col-span-6">
            {isUserModified ? (
              <Input
                label="Processor Dynamic Descriptor"
                name="dynamic_descriptor"
                toolTipShow={true}
                tooltipText={
                  "4 Character DBA Abbreviation * Event Name Example: RPOS*RONIN LOCATION (Max 25 Characters)"
                }
              />
            ) : (
              <Input
                label="Processor Dynamic Descriptor"
                name="dynamic_descriptor"
                value={inputValue}
                onChange={(e) => {
                  setIsUserModified(true);
                }}
                toolTipShow={true}
                tooltipText={
                  "4 Character DBA Abbreviation * Event Name Example: RPOS*RONIN LOCATION (Max 25 Characters)"
                }
              />
            )}
          </div>
        </>
        <div className="col-span-6">
          <PercentageInput
            name="digital_surcharge_percentage"
            label="Service Fee Value (%)"
            defaultValue={0}
          />
        </div>
        {admin && (
            <>
              <div className="col-span-6">
                <TagsInput
                    label="Redeemable Token Types"
                    name="redeemable_tokens"
                    placeholder="Add a Token Type (press 'enter' to add)"
                />
              </div>
              <div className="col-span-1">
                <IndeterminateCheckbox
                    name="users_required"
                    label="Require Clerks?"
                    defaultChecked
                />
              </div>
              <div className="col-span-1">
                <IndeterminateCheckbox
                    name="users_password_required"
                    label="Require Passwords for Clerks?"
                    defaultChecked
                />
              </div>
            </>
        )}
      </FormLayout>
    </Form>
  );
};
