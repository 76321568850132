import { requireAuth } from "../auth/RequireAuth";
import { Dashboard } from "./dashboard";


export const dashboardRouter = {
  path: "/dashboard",
  id: "dashboard",
  element: <Dashboard />,
  loader: requireAuth(undefined),
};
