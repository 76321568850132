import { usdFormat } from "../../../../utils";
import { PromoBalanceReportRow } from "./type";
import {ColDef} from "ag-grid-community";

let totalRow: PromoBalanceReportRow = {
  id: "total-row",
  username: "Total",
  gross_promo_loaded: 0,
  gross_promo_refunded: 0,
  net_promo_loaded: 0,
  event_id: 0,
  event_name: "",
  created_at_hour: "",
  location_id: 0,
  location_name: "",
  organization_id: 0,
  user_id: 0,
};


const generateColDefs = (): ColDef<PromoBalanceReportRow>[] => [
  {
    headerName: 'Username',
    valueGetter: p => p.data?.username,
    pinned: 'left',
    cellStyle: params => {
      if (params.value === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Event Name',
    valueGetter: p => p.data?.event_name,
  },
  {
    headerName: 'Location Name',
    valueGetter: p => p.data?.location_name,
  },
  {
    headerName: 'Gross Promo Balance Loaded',
    field: 'gross_promo_loaded',
    valueGetter: params => {
      return params.data?.gross_promo_loaded;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Gross Promo Balance Refunded',
    field: 'gross_promo_refunded',
    valueGetter: params => {
      return params.data?.gross_promo_refunded;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Net Promo Balance Loaded',
    field: 'net_promo_loaded',
    valueGetter: params => params.data?.net_promo_loaded,
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  }
];

export const colDefs: ColDef<PromoBalanceReportRow>[] = generateColDefs();

export const formatData = (data: PromoBalanceReportRow[]) => {
  if (!data) return [];
  totalRow = {
    id: "total-row",
    username: "Total",
    gross_promo_loaded: 0,
    gross_promo_refunded: 0,
    net_promo_loaded: 0,
    event_id: 0,
    event_name: "",
    created_at_hour: "",
    location_id: 0,
    location_name: "",
    organization_id: 0,
    user_id: 0,
  };

  const groupedRows: Record<string, PromoBalanceReportRow> = {};

  data.forEach((curr) => {
    const username = curr.username;
    const locationName = curr.location_name; // Grouping based on location_name
    const gross_promo_loaded = curr.gross_promo_loaded / 100;
    const gross_promo_refunded = curr.gross_promo_refunded / 100;
    const net_promo_loaded = curr.net_promo_loaded / 100;

    const rowIdentifier = `${username}-${locationName}`; // Combine username and location_name for the unique identifier

    if (groupedRows[rowIdentifier]) {
      const row = groupedRows[rowIdentifier];
      row.gross_promo_loaded += gross_promo_loaded;
      row.gross_promo_refunded += gross_promo_refunded;
      row.net_promo_loaded += net_promo_loaded;
    } else {
      groupedRows[rowIdentifier] = {
        ...curr,
        id: rowIdentifier,
        gross_promo_loaded,
        gross_promo_refunded,
        net_promo_loaded,
      };
    }

    // Update the totals
    totalRow.gross_promo_loaded += gross_promo_loaded;
    totalRow.gross_promo_refunded += gross_promo_refunded;
    totalRow.net_promo_loaded += net_promo_loaded;
  });
  groupedRows[totalRow.id] = totalRow;
  return Object.values(groupedRows);
};