import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

interface ReportHeaderProps {
    title: string;
    toolTipShow?: boolean;
    tooltipText?: string;
}

export default function ReportHeader({
                                         title,
                                         tooltipText,
                                         toolTipShow,
                                     }: ReportHeaderProps) {
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState("left-1/2 -translate-x-1/2");
    const tooltipRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (showTooltip && tooltipRef.current) {
            const tooltipRect = tooltipRef.current.getBoundingClientRect();
            const viewportWidth = window.innerWidth;

            if (tooltipRect.right > viewportWidth) {
                // Adjust tooltip to the left if it overflows the right edge
                setTooltipPosition("right-0");
            } else if (tooltipRect.left < 0) {
                // Adjust tooltip to the right if it overflows the left edge
                setTooltipPosition("left-0");
            } else {
                // Center it by default
                setTooltipPosition("left-1/2 -translate-x-1/2");
            }
        }
    }, [showTooltip]);

    return (
        <div className="pt-4 relative">
            <div className="flex justify-between items-center">
                <div className="flex items-center">
                    <h3 className="text-2xl font-bold">{title}</h3>
                    {toolTipShow && (
                        <span
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                            className="cursor-pointer ml-2 relative"
                        >
                            <FontAwesomeIcon icon={faQuestionCircle} size="1x" />
                            {showTooltip && (
                                <div
                                    ref={tooltipRef}
                                    style={{ zIndex: 1000 }}
                                    className={`absolute top-full ${tooltipPosition} ml-2 mt-2 p-2 border rounded shadow bg-gray-500 text-sm font-medium text-white w-96`}
                                >
                                    {tooltipText}
                                </div>
                            )}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
}
