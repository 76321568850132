import { Badge } from "../../components/Badge";
import {
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
  useRevalidator,
} from "react-router-dom";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { PAYMENT_PROCESSOR_LIST } from "./queries";
import { Link } from "react-router-dom";
import { PaymentProcessor, Events } from "../../types/paymentProcessor";
import { useEffect, useRef, useState } from "react";
import { organizationStore } from "../../store/organization";
import { eventStore } from "../../store/event";
import moment from "moment/moment";
import { usePermissions } from "../auth/api";
import {
  ColDef,
  GridApi,
  SizeColumnsToContentStrategy,
} from "ag-grid-community";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Input } from "../../components/inputs/Input";
import { PrimaryButton, SecondaryButton } from "../../components/Button";
import { AgGridReact } from "ag-grid-react";
import Switch from "react-switch";

const client = createApolloClient();
const { getState } = organizationStore;
const { getState: getEventStore } = eventStore;

const buildSearch = (search: string) => [
  {
    payment_processor: {
      _ilike: `%${search}%`,
    },
  },
];

type CellRenderer = (params: {
  value:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  data: PaymentProcessor;
}) => JSX.Element | null;
const createCellRenderer = (
  permission: boolean,
  linkFunc: (params: { data: PaymentProcessor }) => string
): CellRenderer => {
  return (params) => {
    if (params.value === undefined) {
      return null;
    }
    return (
      <>
        {permission ? (
          <Link to={linkFunc(params)}>
            <Badge>{params.value}</Badge>
          </Link>
        ) : (
          <Badge>{params.value}</Badge>
        )}
      </>
    );
  };
};
const createMultipleLinksCellRenderer = (
  permission: boolean,
  linkFunc: (event: Events) => string
): CellRenderer => {
  return (params) => {
    const events: Events[] = (params.data?.events || []) as unknown as Events[];
    const renderedEvents = events.map((event, index) => (
      <span key={index}>
        {permission ? (
          <Link to={linkFunc(event)}>
            <Badge>{event.name}</Badge>
          </Link>
        ) : (
          <Badge>{event.name}</Badge>
        )}
        {index < events.length - 1 && " "}
      </span>
    ));
    return <>{renderedEvents}</>;
  };
};

export const paymentProcessorListLoader = async ({
  request,
}: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const search = url.searchParams.get("search");
  const rawOrganizationId = getState().organizationId;
  const organizationId = parseInt(rawOrganizationId !== null ? rawOrganizationId.toString() : '', 10);
  const eventId = getEventStore().eventId;

  let where: any = { _or: search ? buildSearch(search) : undefined };
  if (organizationId && organizationId !== 0) {
    where = {
      ...where,
      organization_id: {
        _eq: organizationId,
      },
    };
  }
  // if (eventId) {
  //   where = {
  //     ...where,
  //     event_id: {
  //       _eq: eventId,
  //     },
  //   };
  // }

  const { data } = await client.query({
    query: PAYMENT_PROCESSOR_LIST,
    variables: {
      where,
    },
  });
  return data.payment_processor_config;
};

export const PaymentProcessorList = () => {
  const { editCreatePermission } = usePermissions("payment_processors");
  const { editCreatePermission: eventsProcessorEditPermission } =
    usePermissions("events");
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const gridRef = useRef<GridApi>();
  const processors = useLoaderData() as PaymentProcessor[];
  const [rowData, setRowData] = useState<PaymentProcessor[]>([]);
  const [isActiveToggle, setIsActiveToggle] = useState(true);
  useEffect(() => {
    const filteredItems = isActiveToggle
        ? processors.filter((processor) => processor.is_active)
        : processors;
    setRowData(filteredItems);
  }, [processors, isActiveToggle]);
  const generateColDefs = (): ColDef<PaymentProcessor>[] => [
    {
      headerName: "Id",
      valueGetter: (p) => p.data?.id,
      cellRenderer: createCellRenderer(
        editCreatePermission,
        (p) => `/payment-processors/${p.data.id}/edit`
      ),
      filter: "agTextColumnFilter",
      pinned: "left",
    },
    {
      headerName: "Processor Name",
      valueGetter: (p) => p.data?.payment_processor,
      cellRenderer: createCellRenderer(
        editCreatePermission,
        (p) => `/payment-processors/${p.data.id}/edit`
      ),
      filter: "agTextColumnFilter",
      pinned: "left",
    },
    {
      headerName: "Is Active?",
      valueGetter: (p) => p.data?.is_active,
      valueFormatter: (p) => {
        return p.data?.is_active ? "Yes" : "No";
      },
    },
    {
      headerName: "Processor",
      valueGetter: (p) => p.data?.processor,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Merchant ID",
      valueGetter: (p) => p.data?.config?.merchantId,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Events",
      valueGetter: (p) => p.data?.events,
      valueFormatter: (params) => {
        const events: Events[] = params.value || [];
        return events.map((event) => event.name).join(", ");
      },
      cellRenderer: createMultipleLinksCellRenderer(
        eventsProcessorEditPermission,
        (events) => `/events/${events.id}/edit`
      ),
    },
    {
      headerName: "Processing Rate",
      valueGetter: (p) => p.data?.processing_rate,
      valueFormatter: (p) => {
        return p.value ? `${((p.value || 0) * 100).toFixed(2)}%` : "";
      },
    },
    {
      headerName: "Updated By",
      valueGetter: (p) => p.data?.user?.username,
    },
    {
      headerName: "Created Date",
      valueGetter: (p) => p.data?.created_at,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss a") : "";
      },
    },
    {
      headerName: "Updated Date",
      valueGetter: (p) => p.data?.updated_at,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss a") : "";
      },
    },
  ];
  const colDefs: ColDef<PaymentProcessor>[] = generateColDefs();
  const rowHeight = 50;
  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: "fitCellContents",
  };
  const handleExportCSV = () => {
    if (gridRef.current) {
      const timestamp = new Date().toISOString().replace(/[-:]/g, "");
      const params = {
        fileName: `PaymentProcessors_${timestamp}.csv`,
        columnSeparator: ",",
      };
      gridRef.current.exportDataAsCsv(params);
    }
  };
  const gridOptions = {
    enableCellTextSelection: true,
  };
  return (
    <div style={{ height: "75vh" }}>
      <div
        className={"ag-theme-quartz"}
        style={{ width: "100%", height: "100%" }}
      >
        <div className=" grid gap-2 grid-cols-6">
          <Breadcrumbs/>
          <div className="col-span-1 col-start-4 mt-3 flex flex-col items-center">
            <span className="block text-sm font-medium text-gray-500">Active Only</span>
            <Switch
                checked={isActiveToggle}
                onChange={() => setIsActiveToggle((prev) => !prev)}
                onColor="#EE2E30"
                onHandleColor="#FFFFFF"
                handleDiameter={18}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={15}
                width={48}
            />
          </div>
          <div className="col-span-2 col-start-5 mt-2">
            <Input
                name="search"
                placeholder={`Search Payment Processors`}
                onChange={(s) => {
                  navigate(
                      s && typeof s === "string" && s.length > 0
                          ? `/payment-processors/list?search=${s}`
                          : `/payment-processors/list`
                  );
                  revalidator.revalidate();
                }}
            />
          </div>
          <div className="py-2 col-span-2 col-start-7">
            <SecondaryButton onClick={handleExportCSV}>
              Export to CSV
            </SecondaryButton>
            {editCreatePermission && (
                <PrimaryButton
                    onClick={() => navigate(`/payment-processors/create`)}
                >
                  Create
                </PrimaryButton>
            )}
          </div>
        </div>
        <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            rowHeight={rowHeight}
            autoSizeStrategy={autoSizeStrategy}
            pagination={true}
            gridOptions={gridOptions}
            paginationPageSize={100}
            onGridReady={(params) => (gridRef.current = params.api)}
        />
      </div>
    </div>
  );
};
