import { BlogPostEdit, blogpostEditAction, blogpostEditLoader } from './blogpostEdit';
import {ActionFunctionArgs, redirect} from "react-router-dom";
import {createApolloClient} from "../../../providers/ApolloClientFactory";
import toast from "react-hot-toast";
import {DELETE_BLOG_POST} from "./queries_mutations";
import {BlogPostCreate, blogpostCreateAction} from "./blogpostCreate";

const blogPostDelete = async ({ params }: ActionFunctionArgs) => {
    const { id } = params;
    const client = createApolloClient();
    try {
        await client.mutate({
            mutation: DELETE_BLOG_POST,
            variables: { id },
        });
        toast.success("Blog Post Deleted Successfully");
        return redirect(`/dashboard`);
    } catch {
        toast.error("Failed To Delete Blog Post");
    }
};



export const blogpostRouter = {
    path: "/blogpost",
    id: "blogpost-root",
    children: [
        {
            path: ":id/edit",
            id: "blogpost-edit",
            element: <BlogPostEdit />,
            loader: blogpostEditLoader,
            action: blogpostEditAction,
        },
        {
            path: "create",
            id: "blogpost-create",
            element: <BlogPostCreate />,
            action: blogpostCreateAction,
        },
        {
            path: ":id/delete",
            id: "blogpost-delete",
            action: blogPostDelete,
        },
    ],
};