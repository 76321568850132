import React, { useEffect, useState } from "react";
import { createApolloClient } from "../../../../providers/ApolloClientFactory";
import { REPORT_REFRESHES } from "../../queries/reportRefreshes";
import Loader from "../../../../components/Loader";
import { ReportRefreshes } from "../../type";
import { PrimaryButton } from "../../../../components/Button";
import ReportHeader from "../../../reports/components/ReportHeader";
import { useNavigate } from "react-router-dom";
import { userStore } from "../../../../store/user";
import {ColDef, SizeColumnsToContentStrategy} from "ag-grid-community";
import {Item} from "../../../../types/item";
import {usdFormat} from "../../../../utils";
import moment from "moment/moment";
import {AgGridReact} from "ag-grid-react";

const client = createApolloClient();
const { getState: getUserStore } = userStore;

const AdminCard = () => {
  const [reportRefreshes, setReportRefreshes] = useState<ReportRefreshes[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const user = getUserStore().user;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await client.query({
          query: REPORT_REFRESHES,
          fetchPolicy: "no-cache",
        });
        console.log("Fetched data:", data);
        setReportRefreshes(data.latest_cron_job_log);
      } catch (error) {
        console.error("Error fetching report refreshes:", error);
        setReportRefreshes([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const generateColDefs = (): ColDef<ReportRefreshes>[] => [
    {
      headerName: "Job ID",
      valueGetter: (p) => p.data?.job_id,
      filter: "agTextColumnFilter",
      pinned: "left",
    },
    {
      headerName: "Job Name",
      valueGetter: (p) => p.data?.job_name,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Start Time",
      valueGetter: (p) => p.data?.start_time,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss.SSS a") : "";
      },
    },
    {
      headerName: "End Time",
      valueGetter: (p) => p.data?.end_time,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss.SSS a") : "";
      },
    },
    {
      headerName: "Status",
      valueGetter: (p) => p.data?.status,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Error Message",
      valueGetter: (p) => p.data?.error_message,
      filter: "agTextColumnFilter",
    },
  ];
  const colDefs: ColDef<ReportRefreshes>[] = generateColDefs();
  const rowHeight = 50;
  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: "fitCellContents",
  };
  const gridOptions = {
    enableCellTextSelection: true,
  };
  return (
    <div className="grid w-full overflow-hidden rounded-b-lg bg-white shadow p-5 gap-y-10 py-4">
      <div className="w-full overflow-hidden rounded-lg bg-gray-200 shadow">
        <div className="w-full overflow-hidden rounded-lg shadow grid grid-cols-1 gap-3 p-5">
          <div className="w-full overflow-hidden rounded-lg bg-white m-0 p-5 shadow">
            <ReportHeader title="Report Refresh Status" />
            <div style={{height: "65vh"}}>
              <div
                  className={"ag-theme-quartz"}
                  style={{width: "100%", height: "100%"}}
              >
                <AgGridReact
                    rowData={reportRefreshes}
                    columnDefs={colDefs}
                    rowHeight={rowHeight}
                    autoSizeStrategy={autoSizeStrategy}
                    pagination={true}
                    gridOptions={gridOptions}
                    paginationPageSize={100}
                    // onGridReady={(params) => (gridRef.current = params.api)}
                />
              </div>
            </div>
          </div>
            </div>
          </div>
        </div>
        );
        };

        export default AdminCard;
